import React from "react";
import { Grid, IconButton, Divider, Typography } from "@mui/material";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import { capitalizeFirstLetter } from "../../utils/common.service";
import "./CategoryDetailsForm.css";
import { Box } from "@mui/system";
import { BASE_URL, CONSTANT } from "../../utils/constant";
import moment from "moment";

const CategoryDetailsForm = (props) => {
  const { closeModal, data } = props;

  return (
    <>
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>Category Details</h3>

        <IconButton aria-label="close">
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          sx={{
            overflow: "auto",
          }}
        >
          <Grid sx={{ display: "flex", gap: "10%", mx: "30px" }}>
            <Grid item xs={4}>
              {data?.attachment?.filename && (
                <img
                  src={`${BASE_URL}document/category_media/${data?.attachment?.filename}`}
                  alt="CategoryPoster"
                  className="profileImg"
                />
              )}
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography sx={{ color: "#4f4f4f" }}>Name</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {capitalizeFirstLetter(data?.title) || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ mt: "30px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Description</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?.description || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: "20px", mb: "20px" }} />
          <Grid sx={{ display: "flex", gap: "10%", mx: "30px", mb: "20px" }}>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={{ color: "#4f4f4f" }}> Category Id</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?._id || "-"}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Typography sx={{ color: "#4f4f4f" }}>Updated Date</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {moment(data?.updatedAt).format(CONSTANT.DATE_FORMATE)}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography sx={{ color: "#4f4f4f" }}>Created Date</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {moment(data?.createdAt).format(CONSTANT.DATE_FORMATE)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CategoryDetailsForm;
