import React from "react";
import AuthGuard from "./components/authentication/AuthGuard";
import GuestGuard from "./components/authentication/GuestGuard";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import { Navigate } from "react-router-dom";
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import Verification from "./pages/authentication/Verification";
import ForgetPassword from "./pages/authentication/ForgetPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import DashboardSaaS from "./pages/dashboards/SaaS";
import UserProfile from "./pages/UserProfile";
import UserList from "./pages/userManagement/UserList";
import AddNewUser from "./pages/userManagement/AddNewUser";
import EditUser from "./pages/userManagement/EditUser";
import CustomerLists from "./pages/userManagement/CustomerList";
import Error from "./pages/404";
import Inquiry from "./components/Inquiry/Inquiry";
import Category from "./pages/category/Category";
import CategoryForm from "./pages/category/CategoryForm";
import CategoryDetailsForm from "./pages/category/CategoryDetailsForm";
import AddNewCategory from "./pages/category/AddNewCategory";
import QuizList from "./pages/QuizManagment/QuizList";
import QuizForm from "./pages/QuizManagment/QuizForm";
import QuizDetailsForm from "./pages/QuizManagment/QuizDetailsForm";
import AddNewQuiz from "./pages/QuizManagment/AddNewQuiz";
import UsersHistory from "./pages/authentication/UserPlayHistory/UsersHistory";
import Blog from "./pages/Blog/Blog";

const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "verification",
    element: (
      <GuestGuard>
        <Verification />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password",
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },

      {
        path: "user-profile",
        element: <UserProfile />,
      },

      {
        path: "staffs",
        element: <UserList />,
      },
      {
        path: "users",
        element: <CustomerLists />,
      },

      {
        path: "add-user",
        element: <AddNewUser />,
      },
      {
        path: "edit-user",
        element: <EditUser />,
      },
      {
        path: "inquiry",
        element: <Inquiry />,
      },
      {
        path: "category",
        element: <Category />,
      },
      {
        path: "history",
        element: <UsersHistory />,
      },

      {
        path: "edit-category",
        element: <CategoryForm />,
      },
      {
        path: "detail-category",
        element: <CategoryDetailsForm />,
      },
      {
        path: "add-category",
        element: <AddNewCategory />,
      },
      {
        path: "quiz",
        element: <QuizList />,
      },
      {
        path: "edit-quiz",
        element: <QuizForm />,
      },
      {
        path: "detail-quiz",
        element: <QuizDetailsForm />,
      },
      {
        path: "add-quiz",
        element: <AddNewQuiz />,
      },

      {
        path: "blog",
        element: <Blog />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
