import React, { createContext, useState } from "react";

export const BackdropContext = createContext();

export const BackdropProvider = ({ children }) => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  return (
    <BackdropContext.Provider value={{ backdropOpen, setBackdropOpen }}>
      {children}
    </BackdropContext.Provider>
  );
};
