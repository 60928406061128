import React, { useState } from "react";
import "./CreateBlog.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  BASE_URL,
  BLOG_EDITOR_FORMATE,
  BLOG_EDITOR_MODULES,
} from "../../utils/constant";
import userService from "../../service/user.service";
import { notifyError, notifySuccess } from "../../utils/common.service";
import LightTextField from "../LightTextField";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import NoImage from "../../Image/logo/No-Image.png";

const UpdateBlog = (props) => {
  const { closeModal, blogDataById, pageObj, setPageObj, id, categoryList } =
    props;
  const [editorValue, setEditorValueValue] = useState(
    blogDataById?.description
  );
  const initialCategories = Array.isArray(blogDataById?.categories)
    ? blogDataById?.categories.filter((categories) => categories.trim() !== "")
    : [blogDataById?.categories || ""];
  const [selectedCheckboxes, setSelectedCheckboxes] =
    useState(initialCategories);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [blogTitle, setBlogTitle] = useState(blogDataById?.title);
  const [blogSlug, setBlogSlug] = useState(blogDataById?.slug);
  const [imageFile, setImageFile] = useState();
  const [imageURL, setImageURL] = useState("");

  const handleChangeSlug = (e) => {
    setBlogSlug(e.target.value.replace(/[^A-Z0-9]+/gi, "-"));
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    if (value) {
      setBlogTitle(value);
      setBlogSlug(value.replace(/[^A-Z0-9]+/gi, "-"));
    }
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageURL(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImageURL("");
    }
  };

  const handleUpdateBlog = () => {
    try {
      setButtonLoading(true);
      const formData = new FormData();
      if (imageFile !== undefined) {
        formData.append("attachment", imageFile);
      }
      formData.append("title", blogTitle || blogDataById?.title);
      formData.append(
        "categories",
        JSON.stringify(selectedCheckboxes) || blogDataById?.categories
      );
      formData.append("slug", blogSlug?.toLowerCase() || blogDataById?.slug);

      formData.append("description", editorValue || blogDataById?.description);

      userService
        .updateBlog(id, formData)
        .then((res) => {
          const { status, message } = res;
          if (status === 200) {
            setButtonLoading(false);
            notifySuccess(message);
            setPageObj({
              ...pageObj,
              refresh: Date.now(),
            });
            closeModal();
          } else {
            setButtonLoading(false);
            notifyError(message);
          }
        })
        .catch((err) => {
          setButtonLoading(true);
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setButtonLoading(true);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(checkboxValue)) {
        // If checkbox is already selected, remove it from the array
        return prevSelected.filter((value) => value !== checkboxValue);
      } else {
        // If checkbox is not selected, add it to the array
        return [...prevSelected, checkboxValue];
      }
    });

    // Update your categories array in your data model here using the setSelectedCheckboxes state.
  };

  return (
    <div>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3 className="addBlogTitle">Update Blog</h3>

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </Grid>

      <Divider />

      <Grid container sx={{ margin: "10px 0" }}>
        <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
          <img
            src={
              blogDataById?.attachment?.filename
                ? `${BASE_URL}document/blog_media/${blogDataById?.attachment?.filename}`
                : imageURL
                ? imageURL
                : NoImage
            }
            alt="blogImage"
            className="blogImage"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            as="h5"
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              my: "12px",
            }}
          >
            Image
          </Typography>

          <input
            className="input"
            fullWidth
            type="file"
            id="image"
            accept=".png, .jpg, .jpeg"
            onChange={handleChangeImage}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            as="h5"
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              my: "12px",
            }}
          >
            Title
          </Typography>
          <LightTextField
            fullWidth
            name="title"
            type="text"
            placeholder="Enter Blog Title"
            value={blogTitle || ""}
            onChange={handleChangeTitle}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            as="h5"
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              my: "12px",
            }}
          >
            Slug
          </Typography>
          <LightTextField
            fullWidth
            name="slug"
            type="text"
            placeholder="Enter Blog Slug"
            value={blogSlug?.toLowerCase() || ""}
            onChange={handleChangeSlug}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography
            as="h5"
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              mb: "12px",
            }}
            className="font-size-14px mb-12px font-weight-500"
          >
            Category
          </Typography>

          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              name="categories"
              value={selectedCheckboxes}
              multiple
              // error={Boolean(touched.categories && errors.categories)}
              renderValue={(selected) =>
                selected
                  .map(
                    (categoryId) =>
                      categoryList.find(
                        (category) => category._id === categoryId
                      )?.title
                  )
                  .join(", ")
              }
              className="css-w4xgd5-MuiFormControl-root-MuiTextField-root MuiOutlinedInput-notchedOutline"
              sx={{
                padding: "revert-layer",
                paddingRight: "0",
                paddingLeft: "25px",
              }}
            >
              <FormGroup>
                {categoryList.map((categories, index) => (
                  <FormControlLabel
                    sx={{ ml: 2 }}
                    onChange={handleCheckboxChange}
                    control={
                      <Checkbox
                        checked={selectedCheckboxes.includes(categories._id)}
                        onChange={handleCheckboxChange}
                        value={categories._id}
                      />
                    }
                    label={categories?.title}
                    key={index}
                  />
                ))}
              </FormGroup>
            </Select>
            <Typography
              sx={{
                color: "#FD396D",
                fontSize: "12px",
                fontWeight: 400,
                marginLeft: "12px",
              }}
            >
              {/* {errors.categories && touched.categories && (
                  <p className="error">{errors.categories}</p>
                )} */}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ mb: "12px" }}>
          <Typography
            as="h5"
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              my: "12px",
            }}
          >
            Blog
          </Typography>
          <ReactQuill
            theme="snow"
            value={editorValue}
            onChange={setEditorValueValue}
            placeholder="Write..."
            modules={BLOG_EDITOR_MODULES}
            formats={BLOG_EDITOR_FORMATE}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            loading={buttonLoading}
            loadingPosition="start"
            variant="contained"
            fullWidth
            className="button"
            onClick={() => handleUpdateBlog()}
          >
            Update Blog
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateBlog;
