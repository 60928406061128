import { useEffect } from "react";
import { Grid } from "@mui/material";
import useTitle from "../../hooks/useTitle";
import { PAGE_CONSTANT } from "../../utils/constant";
import "./saas.css";
import logo from "../../Image/logo/Frame.png";

const SaaS = () => {
  useTitle(PAGE_CONSTANT.DASHBOARD);

  useEffect(() => {
    document.title = PAGE_CONSTANT.DASHBOARD;
  }, []);

  return (
    <Grid
      container
      spacing={4}
      pt={4}
      sx={{ display: "block", textAlign: "center" }}
    >
      {" "}
      <img src={logo} alt="Logo" />
    </Grid>
  );
};

export default SaaS;
