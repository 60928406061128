import React from "react";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./ShowPassword.css";

const ShowPassword = ({
  setImageButton,
  passwordType,
  setPasswordType,
  imageButton,
}) => {
  const togglePasswordVisibility = () => {
    const newType = passwordType === "password" ? "text" : "password";
    setPasswordType(newType);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <IconButton
      onClick={() => {
        togglePasswordVisibility();
        setImageButton(!imageButton);
      }}
      onMouseDown={handleMouseDownPassword}
      edge="end"
      className="field-icon"
    >
      {passwordType === "password" ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );
};

export default ShowPassword;
