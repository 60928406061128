import React, { Fragment } from "react";
import { userTokenMethod } from "../../utils/common.service";
import { Navigate } from "react-router-dom";

const GuestGuard = ({ children }) => {
  const token = userTokenMethod("get");
  if (token) {
    return <Navigate to="/dashboard" />;
  }
  return <Fragment>{children}</Fragment>;
};

export default GuestGuard;
