import React from "react";
import "./Blog.css";
import useTitle from "../../hooks/useTitle";
import BlogList from "../../components/Blog/BlogList";

const Blog = () => {
  useTitle("Blog");

  return (
    <>
      <BlogList />
    </>
  );
};

export default Blog;
