import { useContext, useEffect, useState } from "react";
import { Avatar, Box, Card, Grid, Tab } from "@mui/material";
import { MESSAGE, PAGE_CONSTANT } from "../utils/constant";
import useTitle from "../hooks/useTitle";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { H3 } from "../components/Typography";
import jwt_decode from "jwt-decode";
import {
  isArrayEmpty,
  notifySuccess,
  stringAvatar,
} from "../utils/common.service";
import { capitalizeFirstLetter } from "../utils/common.service";
import { LoadingButton } from "@mui/lab";
import LightTextField from "../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BackdropContext } from "./userManagement/BackdropContext";
import userService from "../service/user.service";
import { notifyError } from "../utils/common.service";
import "./UserProfile.css";
import ProfileResetPassword from "./ProfileResetPassword";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const UserProfile = () => {
  useTitle(PAGE_CONSTANT.PROFILE);

  const [selectedTab, setSelectedTab] = useState(0);
  const [profileData, setProfileData] = useState([]);
  const [userId, setUserId] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const { setBackdropOpen } = useContext(BackdropContext);
  const [file, setFile] = useState([]);

  useEffect(() => {
    document.title = PAGE_CONSTANT.PROFILE;
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(btoa("token"));
    const decode = jwt_decode(token);
    setUserId(decode);
    fetchUserById(decode?.userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    name: profileData?.name,
    email: profileData?.email,
    phoneNo: profileData?.phoneNo,
    address: {
      streetName: profileData?.address?.streetName,
      city: profileData?.address?.city,
      state: profileData?.address?.state,
      country: profileData?.address?.country,
      zipCode: profileData?.address?.zipCode,
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGE.NAME),
  });
  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);
          userService
            .updateUserById(userId?.userId, {
              name: values?.name,
              email: values?.email,
              phoneNo: values?.phoneNo,
              address: {
                streetName: values?.address?.streetName,
                city: values?.address?.city,
                state: values?.address?.state,
                country: values?.address?.country,
                zipCode: values?.address?.zipCode,
              },
            })

            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setButtonLoading(false);
                notifySuccess(message);
                fetchUserById(userId?.userId);
              } else {
                setButtonLoading(false);
                notifyError(message);
              }
            })
            .catch((err) => {
              setButtonLoading(false);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  const fetchUserById = (id) => {
    try {
      setBackdropOpen(true);
      userService
        .getUserById(id)
        .then((res) => {
          const { data, message } = res;
          if (res.status === 200) {
            setBackdropOpen(false);
            setProfileData(data);
          } else {
            setProfileData([]);
            setBackdropOpen(false);
            notifyError(message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const tabChangeHandle = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAttachment = (e) => {
    setFile([...file, e.target.files[0]]);

    updateProfile(e.target.files);
  };

  const updateProfile = (file = []) => {
    try {
      setBackdropOpen(true);

      if (isArrayEmpty(file)) {
        notifyError(MESSAGE.NO_FILE_SELECT);
      } else {
        const formData = new FormData();
        formData.append("profile_picture", file[0]);

        userService
          .updateUserProfile(formData)

          .then((res) => {
            const { status, message } = res;
            if (status === 200) {
              setBackdropOpen(false);
              notifySuccess(message);
              setFile("");
              fetchUserById(userId?.userId);
            } else {
              setBackdropOpen(false);
              notifyError(message);
            }
          })
          .catch((err) => {
            setBackdropOpen(false);
            notifyError(err ? err?.response?.data?.message : err?.message);
          });
      }
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  return (
    <>
      <Box p={2}>
        <Grid container>
          <Grid
            item
            xs={2}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              height: 500,
            }}
          >
            <Tabs
              orientation="vertical"
              TabIndicatorProps={{
                style: { background: "rgb(255 217 0)" },
              }}
              value={selectedTab}
              onChange={tabChangeHandle}
              className="detail-tabs"
            >
              <Tab
                label="Basic Details"
                sx={{ fontSize: "16px", fontWeight: 600 }}
                {...a11yProps(0)}
                className="tab-button"
              />
              <Tab
                label="Reset Password"
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                {...a11yProps(1)}
                className="tab-button"
              />
            </Tabs>
          </Grid>

          <Grid item xs={10}>
            <TabPanel value={selectedTab} index={0}>
              <H3 pb={3}>Basic Details</H3>
              <Grid sx={{ display: "flex", gap: "2%" }}>
                <Card
                  sx={{
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
                    overflow: "hidden",
                    width: "25%",
                    minHeight: "350px",
                    p: 3,
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {profileData?.profileImg ? (
                      <img
                        src={profileData?.profileImg}
                        alt="ProfileImage"
                        className="Profile-photo"
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(profileData?.name || "")}
                        sx={{
                          fontSize: "25px",
                          height: "90px",
                          width: "90px",
                          color: "white",
                          fontWeight: "600",
                          backgroundColor: "#126782",
                        }}
                      />
                    )}
                  </Grid>

                  <Grid class="input--file">
                    <span className="input-span">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle cx="12" cy="12" r="3.2" />
                        <path d="M9 2l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2h-3.17l-1.83-2h-6zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                        <path d="M0 0h24v24h-24z" fill="none" />
                      </svg>
                    </span>
                    <input
                      name="Select File"
                      type="file"
                      onChange={handleAttachment}
                    />
                  </Grid>

                  <H3 textAlign="center" pt={2}>
                    {capitalizeFirstLetter(profileData?.name)}
                  </H3>

                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "gray",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                    pt={2}
                  >
                    {profileData?.role?.charAt(0).toUpperCase() +
                      profileData?.role?.slice(1).replace(/_/g, " ") || "-"}
                  </Typography>

                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "gray",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                    pt={1}
                  >
                    {Object.values(profileData?.address || {})
                      .filter((a) => a)
                      .map(
                        (part) => part.charAt(0).toUpperCase() + part.slice(1)
                      )
                      .join(",\r\n")}
                  </Typography>
                </Card>

                <Card
                  sx={{
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
                    overflow: "hidden",
                    width: "75%",
                    height: "500px",
                    p: 3,
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 250px)",
                      }}
                    >
                      <Grid spacing={1.8} container>
                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            Name
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="name"
                            type="text"
                            placeholder="Full Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            Email
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="email"
                            type="email"
                            placeholder="Email Address"
                            value={values.email}
                            onChange={handleChange}
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled
                            sx={{
                              backgroundColor: "#e5eaf2",
                              color: "black",
                              borderRadius: "9px",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            Phone No
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="phoneNo"
                            type="number"
                            placeholder="phone Number"
                            value={values.phoneNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.phoneNo && errors.phoneNo)}
                            helperText={touched.phoneNo && errors.phoneNo}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            Street Name
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="address.streetName"
                            type="text"
                            placeholder="Street Name"
                            value={values.address.streetName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            City
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="address.city"
                            type="text"
                            placeholder="City"
                            value={values.address.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            State
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="address.state"
                            type="text"
                            placeholder="State"
                            value={values.address.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            Country
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="address.country"
                            type="text"
                            placeholder="Country"
                            value={values.address.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              pb: "5px",
                            }}
                          >
                            Zip Code
                          </Typography>
                          <LightTextField
                            fullWidth
                            name="address.zipCode"
                            type="text"
                            placeholder="Zip Code"
                            value={values.address.zipCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid sx={{ mt: 2 }} item xs={12} md={12} lg={12}>
                          <LoadingButton
                            sx={{ maxWidth: "150px" }}
                            type="submit"
                            loading={buttonLoading}
                            loadingPosition="start"
                            variant="contained"
                            fullWidth
                            className="button"
                          >
                            Save
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                </Card>
              </Grid>
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
              <H3 pb={3}>Reset Password</H3>
              <ProfileResetPassword userId={userId} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserProfile;
