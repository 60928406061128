import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Card } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import LightTextField from "../../components/LightTextField";
import { H1, Small } from "../../components/Typography";
import { TextFieldWrapper } from "../../components/authentication/StyledComponents";
import { useFormik } from "formik";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MESSAGE } from "../../utils/constant.jsx";
import logo from "../../Image/logo/Frame1.png";
import "../../CommonCss/Button.css";

const Register = () => {
  const { register, isRegister } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isRegister) {
      navigate("/verification");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegister]);

  const initialValues = {
    name: "",
    email: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
    mode: "manual",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email(MESSAGE.EMAIL_VALID)
      .max(255)
      .required(MESSAGE.EMAIL_REQUIRED),
    password: Yup.string()
      .min(6, MESSAGE.PASSWORD_MINIMUM)
      .required(MESSAGE.PASSWORD_REQUIRED),
    confirmPassword: Yup.string()
      .min(6, MESSAGE.CONFIRM_PASSWORD_MINIMUM)
      .required(MESSAGE.CONFIRM_PASSWORD_REQUIRED),
    phoneNo: Yup.string()
      .min(10, MESSAGE.PHONE_NO_MINIMUM)
      .max(12, MESSAGE.PHONE_NO_MAXIMUM),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,

      onSubmit: async (values) => {
        try {
          setLoading(true);
          if (values.password !== values.confirmPassword) {
            toast.error(MESSAGE.PASSWORD_MATCH);
            setLoading(false);
          } else {
            const obj = {
              name: values?.name || "",
              email: values?.email || "",
              phoneNo: values?.phoneNo?.toString() || "",
              password: values.password || "",
              mode: "manual",
              role: "user",
            };

            const registerUser = await register(obj);

            if (registerUser && registerUser.status === 200) {
              navigate("/verification");
              setLoading(false);
            }
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      },
    });

  return (
    <React.Fragment>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: { sm: "100%" },
        }}
      >
        <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
          <FlexBox
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            mb={1}
          >
            <Box width={130} mb={1}>
              <img src={logo} width="100%" alt="Uko Logo" />
            </Box>
            <H1 fontSize={24} fontWeight={700}>
              Get started with AtoZ Quizzes!
            </H1>
          </FlexBox>

          <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
            <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <FlexBox
                justifyContent="space-between"
                flexWrap="wrap"
                sx={{ mt: 2 }}
              >
                <TextFieldWrapper sx={{ mt: 2 }}>
                  <LightTextField
                    fullWidth
                    autoComplete="off"
                    name="name"
                    type="text"
                    label="Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name || ""}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </TextFieldWrapper>

                <TextFieldWrapper sx={{ mt: 2 }}>
                  <LightTextField
                    fullWidth
                    autoComplete="off"
                    name="email"
                    type="email"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email || ""}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </TextFieldWrapper>
              </FlexBox>

              <FlexBox
                justifyContent="space-between"
                flexWrap="wrap"
                sx={{ mt: 2 }}
              >
                <TextFieldWrapper>
                  <LightTextField
                    fullWidth
                    name="password"
                    type="password"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password || ""}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </TextFieldWrapper>

                <TextFieldWrapper>
                  <LightTextField
                    fullWidth
                    name="confirmPassword"
                    type="password"
                    label="ConfirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword || ""}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </TextFieldWrapper>
              </FlexBox>

              <Box sx={{ mt: 4 }}>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  className="button"
                >
                  Sign Up
                </LoadingButton>
              </Box>
            </form>

            <Small margin="auto" mt={3} color="text.disabled">
              Do you already have an account?
              <Link to="/login">
                <Small color="primary.main">Log in</Small>
              </Link>
            </Small>
          </FlexBox>
        </Card>
      </FlexBox>
    </React.Fragment>
  );
};

export default Register;
