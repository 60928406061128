import React from "react";
import { createContext, useEffect, useReducer } from "react";
import LoadingScreen from "../components/LoadingScreen";
import UserService from "../service/user.service";
import { notifySuccess, notifyError } from "../utils/common.service";
import { userTokenMethod } from "../utils/common.service";
import { MESSAGE } from "../utils/constant.jsx";
import jwtDecode from "jwt-decode";
import { Navigate } from "react-router-dom";

const Types = {
  Init: "INIT",
  Login: "LOGIN",
  Logout: "LOGOUT",
  Register: "REGISTER",
};

// ================================================

const initialState = {
  isAuthenticated: false,
  isRegister: true,
  isInitialized: false,
};

const setSession = (token) => {
  if (token) {
    userTokenMethod("set", token);
  } else {
    userTokenMethod("delete");
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        isRegister: false,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        isRegister: false,
      };
    }
    case "REGISTER": {
      return {
        ...state,
        isRegister: true,
        isAuthenticated: false,
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: (email, password) => Promise.resolve(),
  logout: () => {},
  register: (email, password, username) => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password, mode) => {
    try {
      return UserService.login({
        email,
        password,
        mode,
      })
        .then((res) => {
          if (res?.status === 200) {
            const loginToken = jwtDecode(res.token);
            if (loginToken.role === "customer") {
              localStorage.removeItem(btoa("token"));
              notifyError("You have not a admin");
              Navigate("/login");
            } else {
              setSession(res.token);
              notifySuccess(res.message);
              dispatch({
                type: Types.Login,
              });
              return res;
            }
          } else {
            notifyError(res?.message || MESSAGE.NETWORK_ERROR);
            return res;
          }
        })
        .catch((err) => {
          notifyError(
            err?.response?.data?.message | err?.message || MESSAGE.NETWORK_ERROR
          );
          return {
            error: err?.response?.data?.message || err?.message,
            status: err?.response?.data?.status || 404,
          };
        });
    } catch (err) {
      notifyError(err ? err?.response?.data?.message : err?.message);
      return {
        error: err?.response?.data?.message || err?.message,
        status: err?.response?.data?.status || 404,
      };
    }
  };

  const register = async (userObj) => {
    try {
      return UserService.register({ ...userObj })
        .then((res) => {
          const { status, message } = res;
          if (res) {
            if (status === 200) {
              notifySuccess(message);
              localStorage.setItem(
                btoa("userData"),
                btoa(JSON.stringify(userObj))
              );
              dispatch({
                type: Types.Register,
              });
              return res;
            } else {
              notifyError(message || MESSAGE.NETWORK_ERROR);
              return res;
            }
          }
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
          return {
            error: err?.response?.data?.message || err?.message,
            status: err?.response?.data?.status || 404,
          };
        });
    } catch (err) {
      notifyError(err ? err.response.data.message : err.message);
      return {
        error: err?.response?.data?.message || err?.message,
        status: err?.response?.data?.status || 404,
      };
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  useEffect(() => {
    (async () => {
      try {
        const token = window.localStorage.getItem(btoa("token"));

        if (token) {
          setSession(token);
          dispatch({
            type: Types.Init,
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            isAuthenticated: false,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
