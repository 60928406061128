import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { MESSAGE } from "../../utils/constant.jsx";
import userService from "../../service/user.service";
import { notifyError, notifySuccess } from "../../utils/common.service";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import Button from "@mui/material/Button";

const AddNewCategory = (props) => {
  const { closeForm, setPageObj, pageObj } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [removeButton, setRemoveButton] = useState(false);

  const initialValues = {
    title: "",
    key: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(MESSAGE.title),
    key: Yup.string().required(MESSAGE.key),
  });
  const handleChangeImage = (e) => {
    setRemoveButton(true);

    setImageFile([...imageFile, e.target.files[0]]);
  };
  const handleRemoveImage = () => {
    setImageFile("");
    setRemoveButton(false);
  };
  const { errors, values, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);

          const formData = new FormData();
          formData.append("attachment", imageFile[0] || "");
          formData.append("title", values?.title || "");
          formData.append("key", values?.key?.toLowerCase() || "");
          formData.append("description", values?.description || "");

          userService
            .addCategory(formData)
            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setButtonLoading(false);
                notifySuccess(message);
                setPageObj({
                  ...pageObj,
                  refresh: Date.now(),
                });
                resetForm();
                closeForm();
              } else {
                setButtonLoading(false);
                notifyError(message);
              }
            })
            .catch((err) => {
              setButtonLoading(true);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(true);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <BootstrapDialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-20px",
            marginLeft: "-8px",
            marginBottom: "-10px",
          }}
        >
          <h3>Add Category</h3>

          <IconButton>
            <CloseIcon onClick={closeForm} />
          </IconButton>
        </BootstrapDialogTitle>

        <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Image
            </Typography>
            <Grid
              sx={{
                mb: "10px",
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              {imageFile.length !== 0 ? (
                <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                  {imageFile[0].name || []}
                </Typography>
              ) : null}
              {removeButton && (
                <Button
                  variant="outlined"
                  onClick={handleRemoveImage}
                  sx={{
                    height: "10px",
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  Remove
                </Button>
              )}
            </Grid>
            {!removeButton && (
              <input
                className="input"
                fullWidth
                type="file"
                id="image"
                accept=".png, .jpg, .jpeg"
                onChange={handleChangeImage}
              />
            )}
          </Grid>
          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Title
            </Typography>
            <LightTextField
              fullWidth
              name="title"
              type="text"
              placeholder="Enter category title"
              value={values.title}
              onChange={handleChange}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Key
            </Typography>
            <LightTextField
              fullWidth
              name="key"
              type="text"
              placeholder="Enter category key"
              value={values.key}
              onChange={handleChange}
              error={Boolean(touched.key && errors.key)}
              helperText={touched.key && errors.key}
            />
          </Grid>

          <Grid item sm={12} xs={16}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Description
            </Typography>
            <LightTextField
              fullWidth
              name="description"
              type="textarea"
              row={3}
              placeholder="description"
              value={values.description}
              onChange={handleChange}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </Grid>

          <Grid item>
            <LoadingButton
              type="submit"
              loading={buttonLoading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
            >
              Add Category
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddNewCategory;
