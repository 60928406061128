import {
  GridView as UserGridIcon,
  Login as LoginIcon,
  PersonAdd as AddUserIcon,
  Dehaze,
  PeopleAltOutlined,
  ManageAccountsOutlined,
  SupportAgent,
  RateReviewOutlined,
  DynamicFeedOutlined,
  Payment,
  ContentCopy,
  SettingsBackupRestoreOutlined,
  PaidOutlined,
  NotificationsActiveOutlined,
  FiberManualRecord,
  QuizOutlined,
  Settings,
  LiveHelp,
  SettingsOutlined,
  ManageHistoryOutlined,
  HistoryOutlined,
  LibraryBooksOutlined,
} from "@mui/icons-material";
import DashboardIcon from "./Dashboard";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  DashboardIcon,
  DynamicFeedOutlined,
  ManageAccountsOutlined,
  PeopleAltOutlined,
  RateReviewOutlined,
  SupportAgent,
  LoginIcon,
  AddUserIcon,
  HistoryOutlined,
  Payment,
  ContentCopy,
  SessionsIcon,
  UserGridIcon,
  UserProfileIcon,
  UserManagementIcon,
  Dehaze,
  SettingsBackupRestoreOutlined,
  PaidOutlined,
  NotificationsActiveOutlined,
  FiberManualRecord,
  QuizOutlined,
  Settings,
  LiveHelp,
  SettingsOutlined,
  ManageHistoryOutlined,
  CategoryOutlinedIcon,
  QuizOutlinedIcon,
  LibraryBooksOutlined,
};
