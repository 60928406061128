import { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { BASE_URL, MESSAGE } from "../../utils/constant.jsx";
import userService from "../../service/user.service";
import { notifyError, notifySuccess } from "../../utils/common.service";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";
import Button from "@mui/material/Button";

const CategoryForm = (props) => {
  const { responseData, closeModal, setPageObj, pageObj, id } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [removeButton, setRemoveButton] = useState(false);

  const initialValues = {
    _id: id,
    title: responseData?.title,
    key: responseData?.key,
    description: responseData?.description,
    attachment: responseData?.attachment,
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(MESSAGE.TITLE),
    key: Yup.string().required("Key is Required!"),
  });
  const handleChangeImage = (e) => {
    setRemoveButton(true);
    setImageFile([...imageFile, e.target.files[0]]);
  };
  const handleRemoveImage = () => {
    setImageFile("");
    setRemoveButton(false);
  };
  const { errors, values, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      try {
        setButtonLoading(true);
        const formData = new FormData();
        if (imageFile[0]) {
          formData.append("attachment", imageFile[0] || "");
        }
        formData.append("title", values?.title || "");
        formData.append("key", values?.key?.toLowerCase() || "");
        formData.append("description", values?.description || "");
        userService
          .updateCategory(id, formData)
          .then((res) => {
            const { status, message } = res;
            if (status === 200) {
              setImageFile("");
              setButtonLoading(false);
              notifySuccess(message);

              setPageObj({
                ...pageObj,
                refresh: Date.now(),
              });

              closeModal();
            } else {
              setButtonLoading(false);
              notifyError(message);
            }
          })
          .catch((err) => {
            setButtonLoading(false);
            notifyError(err ? err?.response?.data?.message : err?.message);
          });
      } catch (err) {
        setButtonLoading(false);
        notifyError(err ? err?.response?.data?.message : err?.message);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit} enctype="multipart/form-data">
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>Update Category</h3>

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          sx={{
            overflow: "auto",
          }}
        >
          <Grid sx={{ display: "flex", gap: "10%", ml: "30px" }}>
            <Grid item xs={4}>
              <Grid sx={{ textAlign: "center" }}>
                {responseData?.attachment?.filename && (
                  <img
                    src={`${BASE_URL}document/category_media/${responseData?.attachment?.filename}`}
                    alt="CategoryPoster"
                    className="profileImg"
                  />
                )}
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {imageFile.length !== 0 ? (
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: "600", mb: 1 }}
                  >
                    {imageFile[0].name || []}
                  </Typography>
                ) : null}
                {removeButton && (
                  <Button
                    variant="outlined"
                    onClick={handleRemoveImage}
                    sx={{
                      height: "10px",
                      color: "black",
                      borderColor: "black",
                    }}
                  >
                    Remove
                  </Button>
                )}
              </Grid>

              <Grid class="input--file">
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: "#2499EF",
                    cursor: "pointer",
                  }}
                >
                  Edit Profile
                </Typography>
                <input
                  name="Select File"
                  type="file"
                  onChange={handleChangeImage}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
              <Grid item sm={4} xs={8}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Title
                </Typography>
                <LightTextField
                  fullWidth
                  name="title"
                  type="text"
                  placeholder="Enter category title"
                  value={values.title}
                  onChange={handleChange}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>

              <Grid item sm={4} xs={8}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Key
                </Typography>
                <LightTextField
                  fullWidth
                  name="key"
                  type="text"
                  placeholder="Enter category key"
                  value={values.key}
                  onChange={handleChange}
                  error={Boolean(touched.key && errors.key)}
                  helperText={touched.key && errors.key}
                />
              </Grid>

              <Grid item sm={12} xs={16}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Description
                </Typography>
                <LightTextField
                  fullWidth
                  name="description"
                  type="textarea"
                  row={3}
                  placeholder="description"
                  value={values.description}
                  onChange={handleChange}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              <Grid item>
                <LoadingButton
                  type="submit"
                  loading={buttonLoading}
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  className="button"
                >
                  Update Category
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default CategoryForm;
