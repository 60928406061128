import { useEffect, useState, useContext } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import SearchInput from "../../components/SearchInput";
import StaffTable from "../../components/userManagement/CustomTable";
import useTitle from "../../hooks/useTitle";
import "../../CommonCss/Button.css";
import { BackdropContext } from "./BackdropContext";
import userService from "../../service/user.service";
import {
  StyledPagination,
  capitalizeFirstLetter,
  notifyError,
  notifySuccess,
  returnError,
  showConfirmDialog,
  stringAvatar,
} from "../../utils/common.service";
import CustomizationDialog from "../../components/Layouts/Modal/CustomizationModal";
import UserForm from "../../components/userManagement/UserForm";
import AddNewUser from "./AddNewUser";
import UserDetailsForm from "../../components/userManagement/UserDetailsForm";
import { CONSTANT, MESSAGE, PAGE_CONSTANT } from "../../utils/constant";
import CustomizationUserFormDialog from "../../components/Layouts/Modal/CustomizationUserFormModal";
import { Small } from "../../components/Typography";
import jwt_decode from "jwt-decode";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Stack } from "@mui/system";
import "./UserList.css";
import NodataFound from "../../components/Layouts/NodataFound";
import FilterMenu from "../../components/card/filterMenu";
import moment from "moment";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const UserList = () => {
  useTitle(PAGE_CONSTANT.CATEGORY);
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);

  const [role, setRole] = useState("");
  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 30,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
    role: "",
  });
  const [search, setSearch] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [userId, setUserId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [windowHeight, setWindowHeight] = useState(800);

  useEffect(() => {
    const height = window.innerHeight;
    setWindowHeight(height);

    const token = localStorage.getItem(btoa("token"));
    const decode = jwt_decode(token);
    setRole(decode.role);
  }, []);

  useEffect(() => {
    document.title = PAGE_CONSTANT.CATEGORY;
  }, []);
  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      fetchData({ ...pageObj });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, pageObj]);

  const UserListColumnShape = [
    {
      Header: "Name",
      accessor: "name",
      minWidth: 200,
      Cell: ({ row }) => {
        const { name, profileImg } = row.original;
        return (
          <FlexBox alignItems="center">
            {profileImg ? (
              <img
                src={profileImg || null}
                alt="ProfileImage"
                className="Profile-image"
              />
            ) : (
              <Avatar
                {...stringAvatar(name.toUpperCase() || "")}
                sx={{
                  mb: 0,
                  backgroundColor: "#126782",
                  color: "white",
                  width: "30px",
                  height: "30px",
                  fontSize: "15px",
                }}
              />
            )}
            <FlexBox flexDirection="column" ml={1}>
              <Small color="text.primary">{capitalizeFirstLetter(name)}</Small>
            </FlexBox>
          </FlexBox>
        );
      },
    },

    {
      Header: "Staff Id",
      accessor: "roleId",
      minWidth: 170,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Job Title",
      accessor: "jobTitle",
      minWidth: 170,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Date Of Joining",
      accessor: "dateOfJoining",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Last Working Day",
      accessor: "lastWorkingDate",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Email",
      accessor: "email",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "PhoneNo",
      accessor: "phoneNo",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Role",
      accessor: "role",
      minWidth: 175,
      Cell: ({ value }) => (
        <Small
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            color: "background.paper",
            backgroundColor: "#A798FF",
          }}
        >
          {value.charAt(0).toUpperCase() + value.slice(1).replace(/_/g, " ") ||
            "-"}
        </Small>
      ),
    },

    {
      Header: "Status",
      accessor: "status",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row, value }) => {
        const { status, _id } = row.original;

        return (
          <>
            {role === CONSTANT.ROLE_LIST[0]["id"] ? (
              <FormControl sx={{ cursor: "pointer" }}>
                <Select
                  inputProps={{ IconComponent: () => null }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={(e) => {
                    handleSetStatus(e.target.value, _id);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                      padding: "0px !important",
                      background: "transparent",
                    },
                    ".MuiSelect-select": {
                      padding: 0,
                    },
                    cursor: "pointer",
                  }}
                >
                  {CONSTANT.STATUS_LIST.map((a) => (
                    <MenuItem value={a.id} key={a.id}>
                      <Chip
                        label={a.label}
                        color={
                          a.id === CONSTANT.STATUS_LIST[0]["id"]
                            ? CONSTANT.SUCCESS
                            : a.id === CONSTANT.STATUS_LIST[1]["id"]
                            ? CONSTANT.WARNING
                            : CONSTANT.ERROR
                        }
                        sx={{ cursor: "pointer", minWidth: "100px" }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Chip
                label={capitalizeFirstLetter(status)}
                color={
                  status === CONSTANT.STATUS_LIST[0]["id"]
                    ? CONSTANT.SUCCESS
                    : status === CONSTANT.STATUS_LIST[1]["id"]
                    ? CONSTANT.WARNING
                    : CONSTANT.ERROR
                }
                sx={{ minWidth: "100px" }}
              />
            )}
          </>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row }) => {
        const { _id } = row.original;
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "right" }}>
            <Tooltip
              title="Staff Detail"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
                mr: "10px",
              }}
              onClick={(e) => {
                handleOpenUserDetailsModal(_id);
              }}
            >
              <RemoveRedEyeIcon className="icons-details" color="black" />
            </Tooltip>

            <Tooltip
              title="Edit"
              placement="top-start"
              onClick={(e) => {
                handleOpenModal(_id);
              }}
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
              }}
            >
              <DriveFileRenameOutlineRoundedIcon
                className="icons-edit"
                color="primary"
              />
            </Tooltip>

            {role === CONSTANT.ROLE_LIST[0]["id"] && (
              <Tooltip
                title="Delete"
                placement="top-start"
                sx={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  border: "1px solid",
                  borderRadius: "5px",
                  height: "30px",
                  width: "30px",
                  padding: "2px",
                }}
                onClick={(e) => {
                  handleDeleteUser(_id);
                }}
              >
                <DeleteOutlineOutlinedIcon
                  className="icons-delete"
                  color="error"
                />
              </Tooltip>
            )}
          </FlexBox>
        );
      },
    },
  ];
  const fetchData = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = {
        ...obj,
        pageCount: obj.pageCount - 1,
        filter: {
          fromDate:
            startDate && moment(startDate).isValid()
              ? moment(startDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
          toDate:
            endDate && moment(endDate).isValid()
              ? moment(endDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
        },
      };

      userService
        .fetchUsers({ ...data })
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setUsersData(res?.data || []);
            setTotalRecord(res?.totalRecords || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalRecords || 0);
            setUsersData([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(returnError(err));
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(returnError(err));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const handleCloseModal = () => {
    setUserId("");
    setOpenModal(false);
  };

  const handleOpenModal = async (_id) => {
    if (_id) {
      setUserId(_id);
      fetchUserById(_id);
    } else {
      setOpenModal(true);
    }
  };

  const fetchUserById = (id) => {
    try {
      setBackdropOpen(true);
      userService
        .getUserById(id)
        .then((res) => {
          const { data, message } = res;
          if (res.status === 200) {
            setBackdropOpen(false);
            setResponseData(data);
            setOpenModal(true);
          } else {
            setBackdropOpen(false);
            notifyError(message);
          }
        })
        .catch((err) => {
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleOpenUserDetailsModal = (id) => {
    try {
      setBackdropOpen(true);
      userService
        .getUserById(id)
        .then((res) => {
          const { data, message } = res;
          if (res.status === 200) {
            setBackdropOpen(false);
            setDisplayData(data);
            setOpenUserDetailsModal(true);
          } else {
            setBackdropOpen(false);
            notifyError(message);
          }
        })
        .catch((err) => {
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
  };

  const handleDeleteUser = async (_id) => {
    showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed) => {
      if (confirmed) {
        const responseData = await userService.deleteUser(_id);
        if (responseData && responseData.status === 200) {
          notifySuccess(responseData.message);
          if (usersData.length === 1 && pageObj.pageCount > 1) {
            setPageObj((prevPageObj) => ({
              ...prevPageObj,
              pageCount: prevPageObj.pageCount - 1,
            }));
          } else {
            setPageObj({
              ...pageObj,
              refresh: Date.now(),
            });
          }
        } else {
          notifyError(responseData.message || MESSAGE.NETWORK_ERROR);
        }
      }
    });
  };

  const handleSetStatus = async (status, _id) => {
    const responseData = await userService.updateStatus(_id, status);
    if (responseData && responseData.status === 200) {
      notifySuccess(responseData.message);
      setPageObj({
        ...pageObj,
        refresh: Date.now(),
      });
    } else {
      notifyError(responseData.message);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleResetFilter = () => {
    setDateRange([null, null]);
    setPageObj({
      ...pageObj,
      search: "",
      sortingField: "",
      sortingOrder: "desc",
      role: "",
    });
    setSearch("");
  };

  return (
    <Box pt={2}>
      <Grid
        sx={{ display: "flex", justifyContent: "end" }}
        className="style-box"
      >
        <Button onClick={() => handleOpenModal()} className="button">
          Add New Member
        </Button>
      </Grid>
      <StyledFlexBox sx={{ mt: 2 }} className="style-box-below">
        <SearchInput
          sx={{ width: "inherit" }}
          placeholder="Search staffs..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          setPageObj={setPageObj}
          pageObj={pageObj}
          showReset={true}
          showSort={false}
          showStatus={false}
          showRangePicker={true}
          showExport={true}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleResetFilter={handleResetFilter}
        />
      </StyledFlexBox>

      <Grid>
        <StaffTable
          isSort={true}
          pageObj={pageObj}
          columnShape={UserListColumnShape}
          data={usersData}
          hidePagination={false}
          createSortHandler={createSortHandler}
          setHeight={"calc(63vh - 10px)"}
          setHighHeight={"calc(71vh - 10px)"}
          windowHeight={windowHeight}
        />
        {!backdropOpen &&
          (!usersData || (usersData.length <= 0 && <NodataFound />))}
      </Grid>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <CustomizationDialog
        open={openModal}
        handleClose={handleCloseModal}
        children={
          !userId ? (
            <AddNewUser
              closeForm={handleCloseModal}
              pageObj={pageObj}
              setPageObj={setPageObj}
            />
          ) : (
            <UserForm
              id={userId}
              closeModal={handleCloseModal}
              responseData={responseData}
              pageObj={pageObj}
              setPageObj={setPageObj}
            />
          )
        }
      />

      <CustomizationUserFormDialog
        title="Staff Information"
        open={openUserDetailsModal}
        handleClose={handleCloseUserDetailsModal}
        children={
          <UserDetailsForm
            closeModal={handleCloseUserDetailsModal}
            data={displayData}
          />
        }
      />

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Staff:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "black",
              backgroundColor: "#126782",
            }}
          >
            {totalRecord || 0}
          </Small>
        </Grid>

        {usersData?.length > 0 && (
          <Stack>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj?.pageCount || 0}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        )}
      </Grid>
    </Box>
  );
};

export default UserList;
