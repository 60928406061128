import React from "react";
import { Grid, Card } from "@mui/material";
import { H3 } from "../Typography";

const NodataFound = () => {
  return (
    <Card>
      <Grid sx={{ p: 5, textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <H3>No data found.</H3>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NodataFound;
