import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  FormControl,
  Tooltip,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Select,
  FormGroup,
} from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import DatePicker from "react-datepicker";

import { CONSTANT } from "../../utils/constant.jsx";
import userService from "../../service/user.service";
import {
  notifyError,
  notifySuccess,
  showConfirmDialog,
} from "../../utils/common.service";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const AddNewQuiz = (props) => {
  const { closeForm, setPageObj, pageObj, categoryList } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [removeButton, setRemoveButton] = useState(false);
  const [winnerAnnouncementDate, setWinnerAnnouncementDate] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
  const [prizeRankList, setPrizeRankList] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");

  const initialValues = {
    entryCoin: "",
    description: "",
    userPlaying: "",
    totalQuestion: "",
    totalTimeSecond: "",
    rewardAdsTime: "",
    rewardAdsretry: "",
    question: "",
    option: "",
  };

  const validationSchema = Yup.object().shape({
    entryCoin: Yup.number().required("Entry coin is required"),
    description: Yup.string().required("Description is required"),
    totalTimeSecond: Yup.string().required("Total time is required"),
    rewardAdsTime: Yup.number().required("Reward time is required"),
    rewardAdsretry: Yup.number().required("Reward Ads Retry time is required"),
  });

  const handleChangeSlug = (e) => {
    setSlug(e.target.value.replace(/[^A-Z0-9]+/gi, "-"));
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;

    setTitle(value);
    setSlug(value.replace(/[^A-Z0-9]+/gi, "-"));
  };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    setSelectedCheckboxes((prevSelected) => {
        if (prevSelected.includes(checkboxValue)) {
            // If checkbox is already selected, remove it from the array
            return prevSelected.filter((value) => value !== checkboxValue);
        } else {
            // If checkbox is not selected, add it to the array
            return [...prevSelected, checkboxValue];
        }
    });

    // Update your categories array in your data model here using the setSelectedCheckboxes state.
};


  const handleCheckboxChangeOnOption = (event, index) => {
    const checkboxValue = JSON.parse(event.target.value);

    if (checkboxValue?.value) {
      if (event.target.checked) {
        if (index >= 0 && index < questions.length) {
          const updatedQuestions = [...questions];
          updatedQuestions[index] = {
            ...updatedQuestions[index],
            rightOption: [
              ...(updatedQuestions[index]?.rightOption || []),  
              checkboxValue,
            ],
          };
          setQuestions(updatedQuestions);
        }
      } else {
        if (index >= 0 && index < questions.length) {
          const updatedQuestions = [...questions];
          const removeIndex = updatedQuestions[index]?.rightOption?.findIndex(
            (item) => item.key === checkboxValue?.key
          );
          // remove object
          const newQuestion = updatedQuestions[index]?.rightOption
            .slice(0, removeIndex)
            .concat(
              updatedQuestions[index]?.rightOption?.slice(removeIndex + 1)
            );
          updatedQuestions[index] = {
            ...updatedQuestions[index],
            rightOption: [...newQuestion],
          };
          setQuestions(updatedQuestions);
        }
      }
    } else {
      event.preventDefault();
      return;
    }
  };

  const handleChangeImage = (e) => {
    setRemoveButton(true);

    setImageFile([...imageFile, e.target.files[0]]);
  };

  const handleRemoveImage = () => {
    setImageFile("");
    setRemoveButton(false);
  };

  const { errors, values, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);
          const rewardData = {
            time: values?.rewardAdsTime || 0,
            retry: values?.rewardAdsretry || 2,
          };
          if (title === "" || slug === "") {
            notifyError("Please, Enter Title and slug");
            return;
          }
          const formData = new FormData();
          formData.append("attachment", imageFile[0] || "");
          formData.append("title", title || "");
          formData.append("slug", slug?.toLowerCase() || "");
          formData.append("entryCoin", values?.entryCoin || "");
          formData.append("description", values?.description || "");
          formData.append("categories", JSON.stringify(selectedCheckboxes || []));
          formData.append("userPlaying", values?.userPlaying || 0);
          formData.append("totalQuestion", questions?.length - 1 || 0);
          formData.append("totalTimeSecond", values?.totalTimeSecond || "");
          formData.append("questions", JSON.stringify(questions) || []);
          formData.append("rewardTime", JSON.stringify(rewardData) || {});
          formData.append("prizeRankList", JSON.stringify(prizeRankList) || []);
          formData.append("winnerAnnouncement", winnerAnnouncementDate || "");

          userService
            .addQuiz(formData)
            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setButtonLoading(false);
                notifySuccess(message);
                setPageObj({
                  ...pageObj,
                  refresh: Date.now(),
                });
                resetForm();
                closeForm();
              } else {
                setButtonLoading(false);
                notifyError(message);
              }
            })
            .catch((err) => {
              setButtonLoading(true);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(true);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  const addQuestionHandler = () => {
    if (questions.length > 0) {
      setQuestions((prevData) => [
        ...prevData,
        {
          question: "",
          options: [],
          rightOption: [],
        },
      ]);
    } else {
      setQuestions([
        {
          question: "",
          options: [],
          rightOption: [],
        },
      ]);
    }
  };

  const addOptionHandler = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];

      if (!updatedQuestions[questionIndex].options.length) {
        updatedQuestions[questionIndex].options.push({
          key: "101",
          value: "",
        });
      } else {
        const newKey = (
          parseInt(updatedQuestions[questionIndex].options.slice(-1)[0].key) + 1
        ).toString();

        updatedQuestions[questionIndex].options.push({
          key: newKey,
          value: "",
        });
      }

      return updatedQuestions;
    });
  };

  const removeOption = (index, key) => {
    const updatedQuestions = [...questions];
    const questionToUpdate = { ...updatedQuestions[index] };
    const optionIndex = questionToUpdate.options.findIndex(
      (option) => option.key === key
    );
    const newQuestion = updatedQuestions[index]?.options
      .slice(0, optionIndex)
      .concat(updatedQuestions[index]?.options?.slice(optionIndex + 1));
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      options: [...newQuestion],
    };
    setQuestions(updatedQuestions);
  };

  const questionUpdateHandler = (e, index) => {
    if (index >= 0 && index < questions.length) {
      const updatedQuestions = [...questions]; // Create a copy of the state array
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        question: e.target.value, // Update the 'question' field
      };
      setQuestions(updatedQuestions); // Update the state with the modified array
    }
  };

  const optionUpdateHandler = (e, index, key) => {
    if (index >= 0 && index < questions.length) {
      const updatedQuestions = [...questions]; // Create a copy of the state array
      const questionToUpdate = { ...updatedQuestions[index] }; // Copy the question object to update

      // Find the index of the option to update in the options array
      const optionIndex = questionToUpdate.options.findIndex(
        (option) => option.key === key
      );

      if (optionIndex !== -1) {
        // Update the value of the option at the found index
        questionToUpdate.options[optionIndex] = {
          ...questionToUpdate.options[optionIndex],
          value: e.target.value, // Update the 'value' field of the option
        };

        // Update the specific question in the copied array
        updatedQuestions[index] = questionToUpdate;
        setQuestions(updatedQuestions); // Update the state with the modified array
      }
    }
  };

  const removeQuestion = (index) => {
    showConfirmDialog(CONSTANT.DELETE_QUESTIOPN, async (confirmed) => {
      if (confirmed) {
        const updatedQuestions = questions
          ?.slice(0, index)
          .concat(questions?.slice(index + 1));
        setQuestions(updatedQuestions);
      }
    });
  };

  const addRangeBlockHandler = () => {
    if (prizeRankList?.length > 0) {
      setPrizeRankList((prevData) => [
        ...prevData,
        {
          from: null,
          to: null,
          price: null,
        },
      ]);
    } else {
      setPrizeRankList([
        {
          from: null,
          to: null,
          price: null,
        },
      ]);
    }
  };

  const fromRangeUpdateHandler = (e, index, flag) => {
    if (index >= 0 && index < prizeRankList.length) {
      const updatedPrizeRankList = [...prizeRankList]; // Create a copy of the state array

      if (flag === "from" || flag === "to" || flag === "price") {
        // Update the value at the specified index based on the flag
        updatedPrizeRankList[index][flag] = e.target.value;
        setPrizeRankList(updatedPrizeRankList); // Update the state with the modified array
      }
    }
  };

  const removePriceRankList = (index) => {
    showConfirmDialog(
      "Are you sure to delete this block",
      async (confirmed) => {
        if (confirmed) {
          const updatedBlock = prizeRankList
            ?.slice(0, index)
            .concat(prizeRankList?.slice(index + 1));
          setPrizeRankList(updatedBlock);
        }
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <BootstrapDialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-20px",
            marginLeft: "-8px",
            marginBottom: "-10px",
          }}
        >
          <h3>Add Quiz</h3>

          <IconButton>
            <CloseIcon onClick={closeForm} />
          </IconButton>
        </BootstrapDialogTitle>

        <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Quiz Poster
            </Typography>
            <Grid
              sx={{
                mb: "10px",
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              {imageFile.length !== 0 ? (
                <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                  {imageFile[0].name || []}
                </Typography>
              ) : null}
              {removeButton && (
                <Button
                  variant="outlined"
                  onClick={handleRemoveImage}
                  sx={{
                    height: "10px",
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  Remove
                </Button>
              )}
            </Grid>
            {!removeButton && (
              <input
                className="input"
                fullWidth
                type="file"
                id="image"
                accept=".png, .jpg, .jpeg"
                onChange={handleChangeImage}
              />
            )}
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Title
            </Typography>
            <LightTextField
              fullWidth
              name="title"
              type="text"
              placeholder="Enter category title"
              value={title || ""}
              onChange={handleChangeTitle}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Slug
            </Typography>
            <LightTextField
              fullWidth
              name="slug"
              type="text"
              placeholder="Slug"
              value={slug?.toLowerCase() || ""}
              onChange={handleChangeSlug}
              error={Boolean(touched.slug && errors.slug)}
              helperText={touched.slug && errors.slug}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Entry Coin
            </Typography>
            <LightTextField
              fullWidth
              name="entryCoin"
              type="number"
              placeholder="Enter Entry Coin"
              value={values.entryCoin}
              onChange={handleChange}
              error={Boolean(touched.entryCoin && errors.entryCoin)}
              helperText={touched.entryCoin && errors.entryCoin}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              User Playing
            </Typography>
            <LightTextField
              fullWidth
              name="userPlaying"
              type="number"
              placeholder="Enter User Play Quiz Count"
              value={values.userPlaying}
              onChange={handleChange}
              error={Boolean(touched.userPlaying && errors.userPlaying)}
              helperText={touched.userPlaying && errors.userPlaying}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Winner Announcement
            </Typography>
            <DatePicker
              className="date-picker-modal css-w4xgd5-MuiFormControl-root-MuiTextField-root MuiOutlinedInput-notchedOutline"
              isClearable
              placeholderText="DD/MM/YYYY"
              name="winnerAnnouncementDate"
              selected={winnerAnnouncementDate}
              onChange={(update) => {
                setWinnerAnnouncementDate(update ? update : null);
              }}
              error={Boolean(
                touched.winnerAnnouncementDate && errors.winnerAnnouncementDate
              )}
              helperText={
                touched.winnerAnnouncementDate && errors.winnerAnnouncementDate
              }
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Description
            </Typography>
            <LightTextField
              fullWidth
              name="description"
              type="textarea"
              row={3}
              placeholder="description"
              value={values.description}
              onChange={handleChange}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Total Time [in Second]
            </Typography>
            <LightTextField
              fullWidth
              name="totalTimeSecond"
              type="number"
              placeholder="Enter total Time Second"
              value={values.totalTimeSecond}
              onChange={handleChange}
              error={Boolean(touched.totalTimeSecond && errors.totalTimeSecond)}
              helperText={touched.totalTimeSecond && errors.totalTimeSecond}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Advertising Reward Time [in Second]
            </Typography>
            <LightTextField
              fullWidth
              name="rewardAdsTime"
              type="number"
              placeholder="Enter total Time Second"
              value={values.rewardAdsTime}
              onChange={handleChange}
              error={Boolean(touched.rewardAdsTime && errors.rewardAdsTime)}
              helperText={touched.rewardAdsTime && errors.rewardAdsTime}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Advertising Reward Try
            </Typography>
            <LightTextField
              fullWidth
              name="rewardAdsretry"
              type="number"
              placeholder="Enter total Count of Retry"
              value={values.rewardAdsretry}
              onChange={handleChange}
              error={Boolean(touched.rewardAdsretry && errors.rewardAdsretry)}
              helperText={touched.rewardAdsretry && errors.rewardAdsretry}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
              className="font-size-14px mb-12px font-weight-500"
            >
              Category
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                label="Category"
                name="selectedCheckboxes"
                value={selectedCheckboxes}
                multiple
                error={Boolean(
                  touched.selectedCheckboxes && errors.selectedCheckboxes
                )}
                helperText={
                  touched.selectedCheckboxes && errors.selectedCheckboxes
                }
                renderValue={(selected) =>
                    selected
                      .map((categoryId) =>
                      categoryList.find((category) => category._id === categoryId)?.title
                      )
                      .join(', ')
                  }
                className="css-w4xgd5-MuiFormControl-root-MuiTextField-root MuiOutlinedInput-notchedOutline"
                sx={{
                  padding: "revert-layer",
                  paddingRight: "0",
                  paddingLeft: "25px",
                }}
              >
                <FormGroup>
                  {categoryList.map((category, index) => (
                    <FormControlLabel
                      sx={{ ml: 2 }}
                      onChange={handleCheckboxChange}
                      control={
                          <Checkbox
                        checked={selectedCheckboxes.includes(category._id)}
                        onChange={handleCheckboxChange}
                        value={category._id}
                        />
                      }
                      label={category?.title}
                      key={index}
                    />
                  ))}
                </FormGroup>
              </Select>
              <Typography
                sx={{
                  color: "#FD396D",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft: "12px",
                }}
              >
                {errors.category && touched.category && (
                  <p className="error">{errors.category}</p>
                )}
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ mt: "20px", mb: "20px" }} />

        {prizeRankList?.length > 0 && (
          <Grid
            container
            rowSpacing={{ xs: 3 }}
            columnSpacing={{ xs: 1 }}
            sx={{ pl: 10, pr: 10, pb: 3 }}
            className="cmn-input-brdr"
            style={{ marginTop: "2rem" }}
          >
            {prizeRankList?.map((data, index) => (
              <>
                <Grid item sm={3} xs={3}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      mb: "12px",
                    }}
                  >
                    From
                  </Typography>
                  <LightTextField
                    fullWidth
                    name="from"
                    type="number"
                    key={index}
                    placeholder="From"
                    value={data?.from}
                    onChange={(e) => {
                      fromRangeUpdateHandler(e, index, "from");
                    }}
                    error={Boolean(touched.from && errors.from)}
                    helperText={touched.from && errors.from}
                  />
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      mb: "12px",
                    }}
                  >
                    To
                  </Typography>
                  <LightTextField
                    fullWidth
                    name="to"
                    type="number"
                    key={index}
                    placeholder="to"
                    value={data?.to}
                    onChange={(e) => {
                      fromRangeUpdateHandler(e, index, "to");
                    }}
                    error={Boolean(touched.to && errors.to)}
                    helperText={touched.to && errors.to}
                  />
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      mb: "12px",
                    }}
                  >
                    Price
                  </Typography>
                  <LightTextField
                    fullWidth
                    name="price"
                    type="number"
                    key={index}
                    placeholder="Price"
                    value={data?.price}
                    onChange={(e) => {
                      fromRangeUpdateHandler(e, index, "price");
                    }}
                    error={Boolean(touched.price && errors.price)}
                    helperText={touched.price && errors.price}
                  />
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Tooltip
                    title="Remove Price-Range"
                    placement="top-start"
                    sx={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      marginTop: "40px",
                      border: "1px solid",
                      borderRadius: "5px",
                      height: "30px",
                      width: "30px",
                      padding: "2px",
                    }}
                    onClick={() => {
                      removePriceRankList(index);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon
                      className="icons-delete"
                      color="error"
                    />
                  </Tooltip>
                </Grid>
              </>
            ))}
          </Grid>
        )}

        <Grid
          container
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 1 }}
          sx={{ justifyContent: "end" }}
        >
          <Grid item sx={{ mb: "20px", mt: "20px", pt: "0" }}>
            <LoadingButton
              type="button"
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
              onClick={addRangeBlockHandler}
            >
              Add Range Block
            </LoadingButton>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "20px", mb: "60px" }} />

        {questions?.map((data, index) => (
          <Grid
            container
            rowSpacing={{ xs: 3 }}
            columnSpacing={{ xs: 1 }}
            sx={{ pl: 10, pr: 10 }}
            className="cmn-input-brdr"
            style={{ marginTop: "2rem" }}
          >
            <Grid
              sm={12}
              xs={12}
              sx={{ justifyContent: "end", mt: "15px", display: "flex" }}
            >
              <Tooltip
                title="Delete Question"
                placement="top-start"
                sx={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  border: "1px solid",
                  borderRadius: "5px",
                  height: "30px",
                  width: "30px",
                  padding: "2px",
                }}
                onClick={() => {
                  removeQuestion(index);
                }}
              >
                <DeleteOutlineOutlinedIcon
                  className="icons-delete"
                  color="error"
                />
              </Tooltip>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                Question {index + 1}
              </Typography>
              <LightTextField
                fullWidth
                name="question"
                type="textarea"
                row={3}
                key={index}
                placeholder="Add Question"
                value={data?.question}
                onChange={(e) => {
                  questionUpdateHandler(e, index);
                }}
                error={Boolean(touched.question && errors.question)}
                helperText={touched.question && errors.question}
              />
            </Grid>
            {data?.options?.map((value) => (
              <Grid item sm={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      mr: 1,
                    }}
                  >
                    {CONSTANT.OPTION_LIST.find(
                      (item) => item.key === value?.key
                    )?.value || "-"}
                  </Typography>

                  <FormControlLabel
                    sx={{ ml: 2 }}
                    control={
                      <>
                        <TextField
                          id="input-with-sx"
                          label={value?.key}
                          name="option"
                          type="text"
                          onChange={(e) => {
                            optionUpdateHandler(e, index, value?.key);
                          }}
                          value={value?.value}
                          variant="standard"
                          error={Boolean(touched.option && errors.option)}
                          helperText={touched.option && errors.option}
                        />
                        <Checkbox
                          value={JSON.stringify(value)}
                          key={value?.key}
                          onChange={(event) =>
                            handleCheckboxChangeOnOption(event, index)
                          }
                          checked={data?.rightOption?.find(
                            (row) => row?.key === value?.key
                          )}
                        />
                      </>
                    }
                  />

                  <Tooltip
                    title="Delete Option"
                    placement="top-start"
                    sx={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      border: "1px solid",
                      borderRadius: "5px",
                      height: "30px",
                      width: "30px",
                      padding: "2px",
                    }}
                    onClick={() => {
                      removeOption(index, value?.key);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon
                      className="icons-delete"
                      color="error"
                    />
                  </Tooltip>
                </Box>
              </Grid>
            ))}
            <Grid
              sm={12}
              xs={12}
              sx={{
                mb: "20px",
                mt: "20px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Grid item sm={2} xs={4}>
                <LoadingButton
                  type="button"
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  className="button"
                  onClick={() => addOptionHandler(index)}
                >
                  Add Option
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12} sx={{ mb: "20px" }}>
              <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                Answer:{" "}
                {questions[index]?.rightOption
                  ?.map((data) => data.value)
                  .join(" , ")}
              </Typography>
            </Grid>
          </Grid>
        ))}

        <Grid
          container
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 1 }}
          sx={{ justifyContent: "end" }}
        >
          <Grid item sx={{ mb: "20px", mt: "20px", pt: "0" }}>
            <LoadingButton
              type="button"
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
              onClick={addQuestionHandler}
            >
              Add Question
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
          <Grid item sx={{ mt: "20px" }}>
            <LoadingButton
              type="submit"
              loading={buttonLoading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
            >
              Create Quiz
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddNewQuiz;
