import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    overflowY: "hidden",
    minWidth: "30%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    </>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizationPdfDialog(props) {
  const { open, handleClose, children } = props;

  return (
    <>
      <Card
        sx={{
          display: open ? "block" : "none",
        }}
      >
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{ overflow: "auto", overflowY: "hidden !important" }}
        >
          <div className="bootstrap-dialog-title">
            <DialogContent
              dividers
              sx={{ overflow: "auto", maxHeight: "660px" }}
            >
              {children}
            </DialogContent>
          </div>
        </BootstrapDialog>
      </Card>
    </>
  );
}
