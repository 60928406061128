import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { MESSAGE } from "../../utils/constant.jsx";
import userService from "../../service/user.service";
import {
  capitalizeFirstLetter,
  isArrayEmpty,
  notifyError,
  notifySuccess,
  returnError,
  stringAvatar,
} from "../../utils/common.service";
import { BootstrapDialogTitle } from "../Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";
import FilterMenu from "../card/filterMenu";
import moment from "moment";

const UserForm = (props) => {
  const { responseData, closeModal, setPageObj, pageObj, id } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [allRole, setAllRole] = useState([]);
  const [joiningDate, setJoiningDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [file, setFile] = useState([]);

  useEffect(() => {
    fetchData();
    const dateOfJoining = moment(responseData?.dateOfJoining, "DD/MM/YYYY")?._d;
    const lastWorkingDate = moment(
      responseData?.lastWorkingDate,
      "DD/MM/YYYY"
    )?._d;
    if (moment(dateOfJoining).isValid() && moment(lastWorkingDate).isValid()) {
      setJoiningDate(new Date(dateOfJoining));
      setLastDate(new Date(lastWorkingDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    _id: id,
    profile_picture: null,
    dateOfJoining: responseData?.dateOfJoining,
    lastWorkingDate: responseData?.lastWorkingDate,
    jobTitle: responseData?.jobTitle,
    name: responseData?.name,
    email: responseData?.email,
    role: responseData?.role,
    gender: responseData?.gender,
    phoneNo: responseData?.phoneNo,
    address: {
      streetName: responseData?.address?.streetName,
      city: responseData?.address?.city,
      state: responseData?.address?.state,
      country: responseData?.address?.country,
      zipCode: responseData?.address.zipCode,
    },
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGE.NAME),
  });

  const handleAttachment = (e) => {
    setFile([...file, e.target.files[0]]);
   

    updateProfile(e.target.files);
  };

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);
          userService
            .updateUserById(id, {
              dateOfJoining: moment(new Date(joiningDate)).format("DD/MM/YYYY"),
              lastWorkingDate: moment(new Date(lastDate)).format("DD/MM/YYYY"),
              jobTitle: values.jobTitle,
              name: values.name,
              email: values.email,
              role: values.role,
              gender: values.gender,
              phoneNo: values.phoneNo,
              address: {
                streetName: values.address.streetName,
                city: values.address.city,
                state: values.address.state,
                country: values.address.country,
                zipCode: values.address.zipCode,
              },
            })
            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setButtonLoading(false);
                notifySuccess(message);

                setPageObj({
                  ...pageObj,
                  refresh: Date.now(),
                });

                closeModal();
              } else {
                setButtonLoading(false);
                notifyError(message);
              }
            })
            .catch((err) => {
              setButtonLoading(false);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  const fetchData = async (obj) => {
    try {
      userService
        .getAllRole()
        .then((res) => {
          if (res.status === 200) {
            setAllRole(res?.data || []);
          } else {
            setAllRole([]);
          }
        })
        .catch((err) => {
          notifyError(returnError(err));
        });
    } catch (err) {
      notifyError(returnError(err));
    }
  };
  const updateProfile = (file = []) => {
    try {
      if (isArrayEmpty(file)) {
        notifyError(MESSAGE.NO_FILE_SELECT);
      } else {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("profile_picture", file[0]);
        userService
          .updateUserProfile(formData)

          .then((res) => {
            const { status, message } = res;
            if (status === 200) {
              setFile("");
              notifySuccess(message);
              setPageObj({
                ...pageObj,
                refresh: Date.now(),
              });
            } else {
              notifyError(message);
            }
          })
          .catch((err) => {
            notifyError(err ? err?.response?.data?.message : err?.message);
          });
      }
    } catch (err) {
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };
  return (
    <form onSubmit={handleSubmit} enctype="multipart/form-data">
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        {responseData?.role === "customer" ? (
          <h3>Update User</h3>
        ) : (
          <h3>Update Staff</h3>
        )}

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          sx={{
            overflow: "auto",
          }}
        >
          <Grid sx={{ display: "flex", gap: "10%", ml: "30px" }}>
            <Grid item xs={4}>
              <Grid sx={{ textAlign: "center" }}>
                {responseData?.profileImg ? (
                  <img
                    src={responseData?.profileImg}
                    alt="profileImg"
                    className="profileImg"
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(
                      capitalizeFirstLetter(responseData?.name) || ""
                    )}
                    sx={{
                      mb: 0,
                      backgroundColor: "#126782",
                      color: "white",
                      width: "130px",
                      height: "130px",
                      fontSize: "30px",
                      fontWeight: 500,
                    }}
                  />
                )}
              </Grid>

              <Grid class="input--file">
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: "#2499EF",
                    cursor: "pointer",
                  }}
                >
                  Edit Profile
                </Typography>
                <input
                  name="Select File"
                  type="file"
                  onChange={handleAttachment}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
              <Grid item xs={6}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Name
                </Typography>
                <LightTextField
                  fullWidth
                  name="name"
                  type="text"
                  placeholder="Full Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Email
                </Typography>
                <LightTextField
                  fullWidth
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                  sx={{
                    backgroundColor: "#e5eaf2",
                    color: "black",
                    borderRadius: "9px",
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Phone NO
                </Typography>
                <LightTextField
                  fullWidth
                  name="phoneNo"
                  type="text"
                  placeholder="Phone No"
                  value={values.phoneNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {responseData.role !== "customer" ? (
                <Grid item xs={6}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      mb: "12px",
                    }}
                    className="font-size-14px mb-12px font-weight-500"
                  >
                    Role
                  </Typography>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl fullWidth>
                      <InputLabel>Role</InputLabel>
                      <Select
                        label="Role"
                        name="role"
                        value={values.role}
                        onChange={handleChange}
                      >
                        {allRole
                          .filter(
                            (role) =>
                              role.roleKey !== "customer" &&
                              role.roleKey !== "super_admin"
                          )
                          .map((role, index) => (
                            <MenuItem key={index} value={role?.roleKey}>
                              {role?.roleName}
                            </MenuItem>
                          ))}
                      </Select>
                      <Typography
                        sx={{
                          color: "#FD396D",
                          fontSize: "12px",
                          fontWeight: 400,
                          marginLeft: "12px",
                        }}
                      ></Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Divider sx={{ my: "20px" }} />

          <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                Date of Joining
              </Typography>
              <FilterMenu
                setPageObj={setPageObj}
                pageObj={pageObj}
                showReset={false}
                showRangePicker={false}
                showSort={false}
                showStatus={false}
                showService={false}
                showExport={false}
                showSingleStartDatePicker={true}
                showSingleEndDatePicker={false}
                joiningDate={joiningDate}
                setJoiningDate={setJoiningDate}
                responseData={responseData}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                Last Working Day
              </Typography>
              <FilterMenu
                setPageObj={setPageObj}
                pageObj={pageObj}
                showReset={false}
                showRangePicker={false}
                showSort={false}
                showStatus={false}
                showService={false}
                showExport={false}
                showSingleStartDatePicker={false}
                showSingleEndDatePicker={true}
                lastDate={lastDate}
                setLastDate={setLastDate}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                Job Title
              </Typography>
              <LightTextField
                fullWidth
                name="jobTitle"
                type="text"
                placeholder="Job Title"
                value={values.jobTitle}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
                className="font-size-14px mb-12px font-weight-500"
              >
                Gender
              </Typography>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    label="Gender"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    error={Boolean(touched.gender && errors.gender)}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                Street Name
              </Typography>
              <LightTextField
                fullWidth
                name="address.streetName"
                type="text"
                placeholder="Street Name"
                value={values.address.streetName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                Zip Code
              </Typography>
              <LightTextField
                fullWidth
                name="address.zipCode"
                type="text"
                placeholder="Zip Code"
                value={values.address.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                City
              </Typography>
              <LightTextField
                fullWidth
                name="address.city"
                type="text"
                placeholder="City"
                value={values.address.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                State
              </Typography>
              <LightTextField
                fullWidth
                name="address.state"
                type="text"
                placeholder="State"
                value={values.address.state}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                as="h5"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "12px",
                }}
              >
                Country
              </Typography>
              <LightTextField
                fullWidth
                name="address.country"
                type="text"
                placeholder="Country"
                value={values.address.country}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: "20px" }}>
            <LoadingButton
              sx={{ maxWidth: "200px" }}
              type="submit"
              loading={buttonLoading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
            >
              {responseData?.role === "customer"
                ? "Update User"
                : "Update Staff"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default UserForm;
