import { Grid, IconButton, Typography } from "@mui/material";
import { BootstrapDialogTitle } from "../Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import { capitalizeFirstLetter } from "../../utils/common.service";
import { CONSTANT } from "../../utils/constant";
import moment from "moment";

const FeedBackDetail = (props) => {
  const { closeModal, data } = props;

  const feedbakeCard = (title, value) => {
    return (
      <Grid sx={{ marginTop: "5px", pb: "5px" }}>
        <Grid>
          <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
        </Grid>
        <Grid borderBottom={"0.5px solid #e9e9e9"}>
          <Typography
            align="left"
            sx={{ marginBottom: "5px", color: "gray" }}
            className="letter-spacing"
          >
            {value || "-"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>Inquiry Details</h3>

        <IconButton aria-label="close">
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>
      {feedbakeCard("Topic", data?.original?.topic || "-")}
      {feedbakeCard("Inquiry Id", data?.original?._id || "-")}
      {feedbakeCard(
        "User Name",
        capitalizeFirstLetter(data?.original?.name) || "-"
      )}
      {feedbakeCard("Email", data?.original?.email || "-")}
      {feedbakeCard("Mobile", data?.original?.mobile || "-")}

      {data?.original?.topic === "Business Enquiry" &&
        feedbakeCard(
          "Company Name",
          capitalizeFirstLetter(data?.original?.company_name) || "-"
        )}
      {data?.original?.topic === "Advertising" &&
        (feedbakeCard(
          "Company Name",
          capitalizeFirstLetter(data?.original?.company_name) || "-"
        ),
        feedbakeCard(
          "Company Url",
          capitalizeFirstLetter(data?.original?.company_url) || "-"
        ))}
      {feedbakeCard("Message", data?.original?.message || "-")}

      <Grid sx={{ pb: "5px" }}>
        <Grid>
          <Typography sx={{ fontWeight: 600 }}>CreatedAt</Typography>
        </Grid>
        <Grid>
          <Typography
            align="left"
            sx={{ marginBottom: "5px", color: "gray" }}
            className="letter-spacing"
          >
            {moment(data?.original?.updatedAt).format(CONSTANT.DATE_FORMATE) ||
              "-"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FeedBackDetail;
