import { Avatar, Divider, Grid, IconButton, Typography } from "@mui/material";
import { BootstrapDialogTitle } from "../Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {
  capitalizeFirstLetter,
  stringAvatar,
} from "../../utils/common.service";
import "./UserDetailsForm.css";
import { Box } from "@mui/system";

const UserDetailsForm = (props) => {
  const { closeModal, data } = props;

  return (
    <>
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>User Details</h3>

        <IconButton aria-label="close">
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          sx={{
            overflow: "auto",
          }}
        >
          <Grid sx={{ display: "flex", gap: "10%", mx: "30px" }}>
            <Grid item xs={4}>
              {data?.profileImg ? (
                <img
                  src={data?.profileImg}
                  alt="profileImg"
                  className="profileImg"
                />
              ) : (
                <Avatar
                  {...stringAvatar(data?.name.toUpperCase() || "")}
                  sx={{
                    mb: 0,
                    backgroundColor: "#126782",
                    color: "white",
                    width: "130px",
                    height: "130px",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                />
              )}
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography sx={{ color: "#4f4f4f" }}>Name</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {capitalizeFirstLetter(data?.name) || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: "#4f4f4f" }}> Email</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?.email || "-"}
                </Typography>
              </Grid>
              {data?.role !== "customer" && (
                <Grid item xs={6} sx={{ mt: "30px" }}>
                  <Typography sx={{ color: "#4f4f4f" }}>Role</Typography>
                  <Typography
                    sx={{ fontWeight: 600 }}
                    className="letter-spacing"
                  >
                    {capitalizeFirstLetter(data?.role).replace(/_/g, " ") ||
                      "-"}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6} sx={{ mt: "30px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Phone No</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?.phoneNo || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ mt: "20px" }} />
          <Grid container rowSpacing={3} sx={{ padding: "50px" }}>
            <Grid item xs={6} sm={4}>
              <Typography sx={{ color: "#4f4f4f" }}>Gender</Typography>
              <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                {capitalizeFirstLetter(data?.gender) || "-"}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography sx={{ color: "#4f4f4f" }}>IsVerified</Typography>
              <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                {capitalizeFirstLetter(data?.isEmailVerified)}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography sx={{ color: "#4f4f4f" }}>Location</Typography>
              <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                {data?.address?.state
                  ? `${data?.address?.state},${data?.address?.country}`
                  : "-"}
              </Typography>
            </Grid>

            {data?.role !== "user" && (
              <>
                <Grid item xs={6} sm={4}>
                  <Typography sx={{ color: "#4f4f4f" }}>Zip Code</Typography>
                  <Typography
                    sx={{ fontWeight: 600 }}
                    className="letter-spacing"
                  >
                    {data?.address?.zipCode || "-"}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserDetailsForm;
