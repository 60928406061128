import { useEffect, useRef } from "react";
import {
  Box,
  Pagination,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import ScrollBar from "simplebar-react";
import { isArrayEmpty } from "../../utils/common.service";
import "./CustomTable.css";

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  "& .MuiPaginationItem-page:hover": {
    borderRadius: 20,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    borderRadius: 20,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& .MuiPaginationItem-previousNext": {
    margin: 10,
    borderRadius: 20,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": { backgroundColor: "transparent" },
  },
}));

const QuestionTable = (props) => {
  const {
    data,
    // rowClick,
    pageObj = {},
    columnShape,
    createSortHandler,
    hidePagination,
    isSort = false,
  } = props;
  const theme = useTheme();
  const tableData = useMemo(() => data, [data]);
  const columns = useMemo(() => columnShape, [columnShape]);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const updateTableContainerSize = () => {
      if (tableContainerRef.current) {
        // Get the current width and height of the table container
        // Perform any custom logic to manage the table size based on offsetWidth and offsetHeight
        // For example, you can update the state or do any other necessary adjustments here
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateTableContainerSize);

    // Call the function once initially to set the initial table container size
    updateTableContainerSize();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateTableContainerSize);
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 50 },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const handleChange = (_e, currentPageNo) => {
    gotoPage(currentPageNo - 1);
  };

  const borderColor =
    theme.palette.mode === "light" ? "text.secondary" : "divider";

  return (
    <Box ref={tableContainerRef} className="table-container">
      <ScrollBar style={{ maxHeight: "calc(100vh - 285px)" }}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
          }}
        >
          <TableHead className="sticky-table-header">
            {!isArrayEmpty(headerGroups) &&
              headerGroups.map((headerGroup, i) => (
                <TableRow {...headerGroup?.getHeaderGroupProps()} key={i}>
                  {!isArrayEmpty(headerGroup.headers) &&
                    headerGroup.headers.map((column, j) => {
                      return (
                        <TableCell
                          key={j}
                          {...column.getHeaderProps()}
                          sx={{
                            paddingY: 0,
                            fontSize: 13,
                            fontWeight: 600,
                            borderBottom: 0,
                            color: "text.primary",
                            width: column.width,
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                            "& .MuiTableSortLabel-icon": {
                              color: "black !important",
                            },
                            "&:last-child": { textAlign: "center" },
                            "&:hover": { color: "black" },
                            "&:focus": { color: "black" },
                          }}
                          direction={
                            pageObj["sortingField"] &&
                            (pageObj["sortingField"] === column.id
                              ? pageObj["sortingOrder"]
                              : pageObj["sortingOrder"])
                          }
                        >
                          {isSort && column.id !== "action" ? (
                            <TableSortLabel
                              active={
                                pageObj["sortingField"] === column.id &&
                                column.id
                              }
                              direction={
                                pageObj["sortingField"] &&
                                pageObj["sortingField"] === column.id
                                  ? pageObj["sortingOrder"]
                                  : pageObj["sortingField"]
                              }
                              sx={{
                                color: "black",
                                "&:hover": { color: "black" },
                                "&:focus": { color: "black" },
                              }}
                              sortDirection={
                                (pageObj["sortingField"] &&
                                  (pageObj["sortingField"] === column.id
                                    ? pageObj["sortingOrder"]
                                    : "asc")) ||
                                "asc"
                              }
                              onClick={createSortHandler(column.id)}
                            >
                              {column.render("Header")}
                            </TableSortLabel>
                          ) : (
                            column.render("Header")
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {!isArrayEmpty(page) &&
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={"row_" + i}
                    {...row.getRowProps()}
                    sx={{
                      backgroundColor: "background.paper",
                      "& td:first-of-type": {
                        borderLeft: "1px solid",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        borderColor,
                      },
                      "& td:last-of-type": {
                        textAlign: "center",
                        borderRight: "1px solid",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        borderColor,
                      },
                      "&:last-of-type .MuiTableCell-root": {
                        borderBottom:
                          theme.palette.mode === "dark"
                            ? `1px solid ${theme.palette.divider} !important`
                            : `1px solid ${theme.palette.text.secondary} !important`,
                      },
                    }}
                  >
                    {!isArrayEmpty(row?.cells) &&
                      row.cells.map((cell, j) => (
                        <TableCell
                          key={"cell_" + j}
                          {...cell.getCellProps()}
                          sx={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: "text.disabled",
                            borderTop: "1px solid",
                            borderBottom: "1px solid",
                            borderColor,
                          }}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </ScrollBar>

      {hidePagination && (
        <Stack alignItems="flex-end" marginY={1}>
          <StyledPagination
            count={pageOptions.length}
            shape="rounded"
            onChange={handleChange}
          />
        </Stack>
      )}
    </Box>
  );
};

export default QuestionTable;
