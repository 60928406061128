import { API_CONSTANT } from "../utils/constant.jsx";
import httpCommon from "../core/http.common.jsx";

const userService = {
  register(body) {
    return httpCommon.post(API_CONSTANT.SIGN_UP_DATA, body);
  },

  login(body) {
    return httpCommon.post(API_CONSTANT.LOGIN, body);
  },

  resetPassword(body) {
    return httpCommon.post(API_CONSTANT.RESET_PASSWORD, body);
  },

  forgotPassword(body) {
    return httpCommon.post(API_CONSTANT.FORGET_PASSWORD, body);
  },

  profileResetPassword(body) {
    return httpCommon.post(API_CONSTANT.PROFILE_RESET_PASSWORD, body);
  },

  updateUserProfile(body) {
    return httpCommon.post(API_CONSTANT.UPDATE_USER_PROFILE, body);
  },

  verificationUser(body) {
    return httpCommon.post(API_CONSTANT.VERIFICATION, body);
  },

  fetchUsers(body = {}) {
    return httpCommon.post(API_CONSTANT.FETCH_USERS, body);
  },

  fetchUsersCounts(body = {}) {
    return httpCommon.post(API_CONSTANT.USER_COUNTS, body);
  },

  addUsers(body) {
    return httpCommon.post(API_CONSTANT.ADD_USER, body);
  },

  updateStatus(id, status) {
    const url = `${API_CONSTANT.UPDATE_STATUS + id}`;
    return httpCommon.put(url, { status });
  },

  getUserById(_id) {
    const url = `${API_CONSTANT.FETCH_USER_BY_ID + _id}`;
    return httpCommon.get(url);
  },

  getCoinById(_id) {
    const url = `${API_CONSTANT.FETCH_COIN_HISTORY_BY_ID + _id}`;
    return httpCommon.get(url);
  },

  updateUserById(_id, body = {}) {
    const url = `${API_CONSTANT.UPDATE_USER + _id}`;
    return httpCommon.put(url, body);
  },

  deleteUser(id, Body) {
    const url = `${API_CONSTANT.DELETE_USER_BY_ID + id}`;
    return httpCommon.delete(url, Body, { id });
  },

  fetchFeedback(body) {
    return httpCommon.post(API_CONSTANT.FEEDBACK, body);
  },

  updateFeedback(_id, body = {}) {
    const url = `${API_CONSTANT.FEEDBAck_UPDATE + _id}`;
    return httpCommon.put(url, body);
  },

  getFeedbackById(_id) {
    const url = `${API_CONSTANT.FETCH_FEEDBACK_BY_ID + _id}`;
    return httpCommon.get(url);
  },

  deleteInquiry(body) {
    return httpCommon.delete(API_CONSTANT.DELETE_INQUIRY, { data: body });
  },

  fetchCategory(body) {
    return httpCommon.post(API_CONSTANT.FETCH_CATEGORY, body);
  },

  deleteCategory(_id) {
    return httpCommon.delete(API_CONSTANT.DELETE_CATEGORY + _id);
  },

  updateCategory(_id, body = {}) {
    const url = `${API_CONSTANT.EDIT_CATEGORY + _id}`;
    return httpCommon.put(url, body);
  },

  addCategory(body) {
    return httpCommon.post(API_CONSTANT.ADD_CATEGORY, body);
  },

  getCategoryById(_id) {
    const url = `${API_CONSTANT.FETCH_CATEGORY_BY_ID + _id}`;
    return httpCommon.get(url);
  },

  fetchQuiz(body) {
    return httpCommon.post(API_CONSTANT.FETCH_QUIZ, body);
  },

  deleteQuiz(_id) {
    return httpCommon.delete(API_CONSTANT.DELETE_QUIZ + _id);
  },

  updateQuiz(_id, body = {}) {
    const url = `${API_CONSTANT.EDIT_QUIZ + _id}`;
    return httpCommon.put(url, body);
  },

  addQuiz(body) {
    return httpCommon.post(API_CONSTANT.ADD_QUIZ, body);
  },

  getQuizById(_id) {
    const url = `${API_CONSTANT.FETCH_QUIZ_BY_ID + _id}`;
    return httpCommon.get(url);
  },

  fetchResultData(body) {
    return httpCommon.post(API_CONSTANT.FETCH_RESULT_DATA, body);
  },

  deleteCoinHistory(_id) {
    return httpCommon.delete(API_CONSTANT.DELETE_COIN_HISTORY + _id);
  },

  createBlog(body) {
    return httpCommon.post(API_CONSTANT.CREATE_BLOG, body);
  },

  fetchBlogs(body = {}) {
    return httpCommon.post(API_CONSTANT.GET_ALL_BLOGS, body);
  },

  getBlogById(_id) {
    const url = `${API_CONSTANT.GET_BLOG_BY_ID + _id}`;
    return httpCommon.get(url);
  },

  updateBlog(_id, body = {}) {
    const url = `${API_CONSTANT.UPDATE_BLOG_BY_ID + _id}`;
    return httpCommon.put(url, body);
  },

  deleteBlog(_id) {
    return httpCommon.delete(API_CONSTANT.DELETE_BLOG_BY_ID + _id);
  },
};

export default userService;
