import { useEffect, useState, useContext } from "react";
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Avatar,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../FlexBox";
import SearchInput from "../SearchInput";
import useTitle from "../../hooks/useTitle";
import FeedBackDetail from "./InquiryBackDetailForm";
import { Small } from "../Typography";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FeedBackTable from "../userManagement/CustomTable";
import { BackdropContext } from "../../pages/userManagement/BackdropContext";
import userService from "../../service/user.service";
import {
  StyledPagination,
  capitalizeFirstLetter,
  notifyError,
  notifySuccess,
  returnError,
  showConfirmDialog,
  stringAvatar,
} from "../../utils/common.service";
import { Stack } from "@mui/system";
import NodataFound from "../Layouts/NodataFound";
import "./inquiry.css";
import { CONSTANT, MESSAGE, PAGE_CONSTANT } from "../../utils/constant";
import FilterMenu from "../card/filterMenu";
import moment from "moment";
import { yellow } from "@mui/material/colors";
import CustomizationPdfDialog from "../Layouts/Modal/PdfModal";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const Inquiry = () => {
  useTitle(PAGE_CONSTANT.FEED_BACK_LIST);

  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 5,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
  });
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [totalRecord, setTotalRecord] = useState("");
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);
 
  const [feedbackData, setFeedbackData] = useState([]);
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [checkData, setCheckData] = useState([]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [windowHeight, setWindowHeight] = useState(800);

  useEffect(() => {
    document.title = PAGE_CONSTANT.FEED_BACK_LIST;
  }, []);

  useEffect(() => {
    const height = window.innerHeight;
    setWindowHeight(height);

    if ((startDate && endDate) || (!startDate && !endDate)) {
      fetchFeedback({ ...pageObj });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, pageObj]);

  const FeedbackListColumn = [
    {
      Header: "Select",
      accessor: "_id",
      minWidth: 20,
      maxWidth: 40,
      Cell: ({ value }) => {
        return (
          <>
            <Checkbox
              {...label}
              sx={{
                color: yellow,
                "&.Mui-checked": {
                  color: yellow[600],
                },
              }}
              onChange={(e) => handleSelectFeedback(e, value)}
              checked={checkData.includes(value)}
            />
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      minWidth: 150,
      Cell: ({ row }) => {
        const { name, profileImg } = row.original;
        return (
          <FlexBox alignItems="center">
            {profileImg ? (
              <img
                src={profileImg || null}
                alt="ProfileImage"
                className="Profile-image"
              />
            ) : (
              <Avatar
                {...stringAvatar(name.toUpperCase() || "")}
                sx={{
                  mb: 0,
                  backgroundColor: "#126782",
                  color: "white",
                  width: "30px",
                  height: "30px",
                  fontSize: "15px",
                }}
              />
            )}
            <FlexBox flexDirection="column" ml={1}>
              <Small color="text.primary">{capitalizeFirstLetter(name)}</Small>
            </FlexBox>
          </FlexBox>
        );
      },
    },
    {
      Header: "InquiryId",
      accessor: "Id",
      minWidth: 150,
      Cell: ({ row }) => {
        const { _id } = row.original;
        return (
          <>
            <Small
              sx={{
                color: "text.primary",
              }}
              className="post-text-ellipsis post-text-ellipsis-dot transform-text-ellipsis transform-text-ellipsis-dot post-id"
            >
              <Tooltip title={_id} placement="top-start">
                {_id || "-"}
              </Tooltip>
            </Small>
          </>
        );
      },
    },

    {
      Header: "Email",
      accessor: "email",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {capitalizeFirstLetter(value) || "-"}
        </Small>
      ),
    },

    {
      Header: "Message",
      accessor: "message",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
          className="text-ellipsis"
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Date",
      accessor: "updatedAt",
      minWidth: 100,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {value ? moment(value).format(CONSTANT.DATE_FORMATE) : "-"}
            </Small>
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {value ? moment(value, "HH:mm").format("hh:mm A") : ""}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      width: 50,

      Cell: ({ row }) => {
        const { reply } = row.original;
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "right" }}>
            <Tooltip
              title="Preview"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
              }}
              className={!reply && "detail-btn"}
              onClick={(e) => {
                handleOpenUserDetailsModal(row);
              }}
            >
              <RemoveRedEyeIcon className="icons-details" color="black" />
            </Tooltip>
          </FlexBox>
        );
      },
    },
  ];

  const handleSelectFeedback = (e, id) => {
    const checked = e.target.checked;
    if (checked) {
      setCheckData((prev) => [...prev, id]);
    } else {
      setCheckData((prev) => prev.filter((item) => item !== id));
    }
  };

  const fetchFeedback = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = {
        ...obj,
        pageCount: obj.pageCount - 1,
        filter: {
          fromDate:
            startDate && moment(startDate).isValid()
              ? moment(startDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
          toDate:
            endDate && moment(endDate).isValid()
              ? moment(endDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
        },
      };

      userService
        .fetchFeedback({ ...data })
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setFeedbackData(res?.data || []);
            setTotalRecord(res?.totalRecords || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalRecords || 0);
            setFeedbackData([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(returnError(err));
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(returnError(err));
    }
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const handleOpenUserDetailsModal = (row) => {
    setOpenUserDetailsModal(true);
    setDisplayData({ ...row });
  };

  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleDeleteFeedback = async () => {
    showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed) => {
      if (confirmed) {
        const responseData = await userService.deleteInquiry({
          id: checkData,
        });
        if (responseData && responseData.status === 200) {
          notifySuccess(responseData.message);
          setCheckData([]);

          setPageObj({
            ...pageObj,
            refresh: Date.now(),
          });
        } else {
          setCheckData([]);
          notifyError(responseData.message || MESSAGE.NETWORK_ERROR);
        }
      }
    });
  };

  const handleResetFilter = () => {
    setDateRange([null, null]);
    setPageObj({
      ...pageObj,
      search: "",
      sortingField: "",
      sortingOrder: "desc",
      rate: "",
    });
    setSearch("");
  };

  return (
    <Box pt={2}>
      <StyledFlexBox sx={{ pb: "20px" }} className="style-box">
        <SearchInput
          placeholder="Search feedbacks..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          rateList={CONSTANT.RATE_LIST}
          setPageObj={setPageObj}
          pageObj={pageObj}
          showReset={true}
          showSort={false}
          showStatus={false}
          showRangePicker={true}
          showExport={false}
          showDelete={true}
          showRate={true}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          checkData={checkData}
          handleResetFilter={handleResetFilter}
          handleDeleteFeedback={handleDeleteFeedback}
        />
      </StyledFlexBox>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Grid>
        <FeedBackTable
          columnShape={FeedbackListColumn}
          data={feedbackData}
          hidePagination={false}
          isSort={true}
          createSortHandler={createSortHandler}
          pageObj={pageObj}
          onChange={handleChange}
          onRowsPerPageChange={handleRowsChange}
          totalRecord={totalRecord}
          users={"Total Record:"}
          setHeight={"calc(67vh - 10px)"}
          setHighHeight={"calc(72vh - 10px)"}
          windowHeight={windowHeight}
        />

        {!backdropOpen &&
          (!feedbackData || (feedbackData.length <= 0 && <NodataFound />))}
      </Grid>

      <CustomizationPdfDialog
        title="feedback"
        open={openUserDetailsModal}
        handleClose={handleCloseUserDetailsModal}
        children={
          <FeedBackDetail
            closeModal={handleCloseUserDetailsModal}
            data={displayData}
          />
        }
      />

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Record:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#126782",
            }}
          >
            {totalRecord || 0}
          </Small>
        </Grid>

        {feedbackData?.length > 0 && (
          <Stack>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj?.pageCount || 0}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        )}
      </Grid>
    </Box>
  );
};

export default Inquiry;
