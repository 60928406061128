import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {
  notifyError,
  notifySuccess,
  showConfirmDialog,
} from "../../utils/common.service";
import "../../components/userManagement/UserDetailsForm.css";
import { Box } from "@mui/system";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FlexBox from "../../components/FlexBox";
import { Small } from "../../components/Typography";
import moment from "moment";
import { CONSTANT, MESSAGE } from "../../utils/constant";
import CustomerTable from "../../components/userManagement/CustomTable";
import NodataFound from "../../components/Layouts/NodataFound";
import userService from "../../service/user.service";

const CoinHistory = (props) => {
  const { closeModal, userId, fetchCoinById, data, backdropOpen } = props;

  const UserListColumnShape = [
    {
      Header: "User Id",
      accessor: "userId",
      minWidth: 100,
      Cell: ({ value }) => (
        <Tooltip title={value}>
          <Small
            sx={{
              color: "text.primary",
            }}
            className="post-text-ellipsis post-text-ellipsis-dot transform-text-ellipsis transform-text-ellipsis-dot"
          >
            {value || "-"}
          </Small>
        </Tooltip>
      ),
    },
    {
      Header: "Quiz Id",
      accessor: "quizId",
      minWidth: 100,
      Cell: ({ value }) => (
        <Tooltip title={value}>
          <Small
            sx={{
              color: "text.primary",
            }}
            className="post-text-ellipsis post-text-ellipsis-dot transform-text-ellipsis transform-text-ellipsis-dot"
          >
            {value || "-"}
          </Small>
        </Tooltip>
      ),
    },
    {
      Header: "Coin Id",
      accessor: "_id",
      minWidth: 100,
      Cell: ({ value }) => (
        <Tooltip title={value}>
          <Small
            sx={{
              color: "text.primary",
            }}
            className="post-text-ellipsis post-text-ellipsis-dot transform-text-ellipsis transform-text-ellipsis-dot"
          >
            {value || "-"}
          </Small>
        </Tooltip>
      ),
    },
    {
      Header: "Quiz Title",
      accessor: "quizTitle",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Coin",
      accessor: "coin",
      minWidth: 50,
      maxWidth: 80,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      minWidth: 140,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {value ? moment(value).format(CONSTANT.DATE_FORMATE) : "-"}
            </Small>
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {value ? moment(value, "HH:mm").format("hh:mm A") : ""}
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Updated Date",
      accessor: "updatedAt",
      minWidth: 140,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {value ? moment(value).format(CONSTANT.DATE_FORMATE) : "-"}
            </Small>
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {value ? moment(value, "HH:mm").format("hh:mm A") : ""}
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row }) => {
        const { _id } = row.original;
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "center" }}>
            <Tooltip
              title="Delete"
              placement="top-start"
              sx={{
                cursor: "pointer",
                marginLeft: "10px",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
              }}
              onClick={(e) => {
                handleDeleteQuiz(_id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                className="icons-delete"
                color="error"
              />
            </Tooltip>
          </FlexBox>
        );
      },
    },
  ];

  const handleDeleteQuiz = async (_id) => {
    showConfirmDialog(
      "Are You Sure to delete this Coin History?",
      async (confirmed) => {
        if (confirmed) {
          const responseData = await userService.deleteCoinHistory(_id);
          if (responseData && responseData.status === 200) {
            notifySuccess(responseData.message);
            fetchCoinById(userId);
          } else {
            notifyError(responseData.message || MESSAGE.NETWORK_ERROR);
          }
        }
      }
    );
  };

  return (
    <>
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>Coin History</h3>

        <IconButton aria-label="close">
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          sx={{
            overflow: "auto",
          }}
        >
          <Grid>
            <CustomerTable
              isSort={false}
              columnShape={UserListColumnShape}
              data={data}
              hidePagination={false}
              setHeight={"calc(50vh - 10px)"}
              setHighHeight={"calc(55vh - 10px)"}
            />
            {!backdropOpen && (!data || (data.length <= 0 && <NodataFound />))}
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Records:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#126782",
            }}
          >
            {data.length || 0}
          </Small>
        </Grid>
      </Box>
    </>
  );
};

export default CoinHistory;
