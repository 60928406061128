import { Pagination, alpha, styled } from "@mui/material";
import toast from "react-hot-toast";
import swal from "sweetalert2";
import { CONSTANT } from "./constant";
import { Box } from "@mui/system";
import FlexBox from "../components/FlexBox";

export const userTokenMethod = (methodType, data) => {
  if (methodType === "set") {
    localStorage.setItem(btoa("token"), data);
  } else if (methodType === "get") {
    return localStorage.getItem(btoa("token"));
  } else if (methodType === "delete") {
    localStorage.removeItem(btoa("token"));
  }
};

export const isArrayEmpty = (array) =>
  typeof array === CONSTANT.UNDEFINED || array === null || array.length <= 0;

export const notifyError = (message) => toast.error(message);

export const notifySuccess = (message) => toast.success(message);

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  "& .MuiPaginationItem-page:hover": {
    borderRadius: 20,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    borderRadius: 20,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& .MuiPaginationItem-previousNext": {
    margin: 10,
    borderRadius: 20,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": { backgroundColor: "transparent" },
  },
}));

export const sentenceCase = (string) => {
  if (string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence;
  }
  return string;
};

export const stringAvatar = (name) => {
  if (!name) return;
  return {
    sx: {
      fontSize: 12,
      height: "40px",
      width: "40px",
      mb: "10px",
      bgcolor: "#126782",
      color: "white",
      fontWeight: "600",
    },
    children: `${sentenceCase(name.split()[0][0])}`,
  };
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary[200]
      : alpha(theme.palette.primary[100], 0.1),
}));

export const SimplifyDate = (x) => {
  const date = new Date(x);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const returnError = (err) => {
  return (
    err?.response?.data?.message || err?.message || CONSTANT.SOMETHING_WRONG
  );
};

export const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

export const showConfirmDialog = (message, callback) => {
  new swal({
    iconColor: "black",
    className: "alert",
    title: message,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#023047",
    cancelButtonColor: "white",
    confirmButtonText: "Yes, delete it!",
    width: "24rem",
    height: "18rem",
    zIndex: "9999",
  }).then((result) => {
    if (result.value) {
      callback(result.value);
    } else {
      callback(false);
    }
  });
};

export const capitalizeFirstLetter = (str, defaultValue = "-") => {
  if (!str) {
    return defaultValue;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
