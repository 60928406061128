import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { MESSAGE } from "../../utils/constant.jsx";
import userService from "../../service/user.service";
import {
  notifyError,
  notifySuccess,
  returnError,
} from "../../utils/common.service";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import FilterMenu from "../../components/card/filterMenu";
import moment from "moment";

const AddNewUser = (props) => {
  const { closeForm, setPageObj, pageObj } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [joiningDate, setJoiningDate] = useState();
  const [lastDate, setLastDate] = useState();
  const [allRole, setAllRole] = useState([]);

  useEffect(() => {
    getAllRole();
  }, []);

  const initialValues = {
    name: "",
    email: "",
    role: "",
    jobTitle: "",
    mobileNo: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGE.NAME),
    email: Yup.string().email().required(MESSAGE.EMAIL_REQUIRED),
    role: Yup.string().required(MESSAGE.ROLE),
  });

  const { errors, values, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);
          const newJoiningDate = moment(new Date(joiningDate)).format(
            "DD/MM/YYYY"
          );
          const newLastDate = moment(new Date(lastDate)).format("DD/MM/YYYY");
          userService
            .addUsers({
              name: values.name || "",
              email: values.email || "",
              role: values.role || "",
              dateOfJoining: newJoiningDate || null,
              lastWorkingDate: newLastDate || null,
              jobTitle: values.jobTitle || "",
              mobileNo: values.mobileNo || "",
              mode: "manual",
            })
            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setButtonLoading(false);
                notifySuccess(message);
                setPageObj({
                  ...pageObj,
                  refresh: Date.now(),
                });
                resetForm();
                closeForm();
              } else {
                setButtonLoading(false);
                notifyError(message);
              }
            })
            .catch((err) => {
              setButtonLoading(true);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(true);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  const getAllRole = async (obj) => {
    try {
      userService
        .getAllRole()
        .then((res) => {
          if (res.status === 200) {
            setAllRole(res?.data || []);
          } else {
            setAllRole([]);
          }
        })
        .catch((err) => {
          notifyError(returnError(err));
        });
    } catch (err) {
      notifyError(returnError(err));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <BootstrapDialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-20px",
            marginLeft: "-8px",
            marginBottom: "-10px",
          }}
        >
          <h3>Add Member</h3>

          <IconButton>
            <CloseIcon onClick={closeForm} />
          </IconButton>
        </BootstrapDialogTitle>

        <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
              className="font-size-14px mb-12px font-weight-500"
            >
              Date of Joining
            </Typography>
            <FilterMenu
              setPageObj={setPageObj}
              pageObj={pageObj}
              showReset={false}
              showRangePicker={false}
              showSort={false}
              showStatus={false}
              showService={false}
              showExport={false}
              showSingleStartDatePicker={true}
              showSingleEndDatePicker={false}
              joiningDate={joiningDate}
              setJoiningDate={setJoiningDate}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
              className="font-size-14px mb-12px font-weight-500"
            >
              Last Working Day
            </Typography>
            <FilterMenu
              setPageObj={setPageObj}
              pageObj={pageObj}
              showReset={false}
              showRangePicker={false}
              showSort={false}
              showStatus={false}
              showService={false}
              showExport={false}
              showSingleStartDatePicker={false}
              showSingleEndDatePicker={true}
              lastDate={lastDate}
              setLastDate={setLastDate}
            />
          </Grid>
          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Job Title
            </Typography>
            <LightTextField
              fullWidth
              name="jobTitle"
              type="jobTitle"
              placeholder="Enter Job Title..."
              value={values.jobTitle}
              onChange={handleChange}
              error={Boolean(touched.jobTitle && errors.jobTitle)}
              helperText={touched.jobTitle && errors.jobTitle}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Name
            </Typography>
            <LightTextField
              fullWidth
              name="name"
              type="text"
              placeholder="Full Name"
              value={values.name}
              onChange={handleChange}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Email
            </Typography>
            <LightTextField
              fullWidth
              name="email"
              type="email"
              placeholder="Email Address"
              value={values.email}
              onChange={handleChange}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Mobile Number
            </Typography>
            <LightTextField
              fullWidth
              name="mobileNo"
              type="number"
              placeholder="Mobile Number"
              value={values.mobileNo}
              onChange={handleChange}
              error={Boolean(touched.mobileNo && errors.mobileNo)}
              helperText={touched.mobileNo && errors.mobileNo}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
              className="font-size-14px mb-12px font-weight-500"
            >
              Role
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                name="role"
                value={values.role}
                onChange={handleChange}
                error={Boolean(touched.role && errors.role)}
              >
                {allRole
                  .filter(
                    (role) =>
                      role.roleKey !== "customer" &&
                      role.roleKey !== "super_admin"
                  )
                  .map((role, index) => (
                    <MenuItem key={index} value={role?.roleKey}>
                      {role?.roleName}
                    </MenuItem>
                  ))}
              </Select>
              <Typography
                sx={{
                  color: "#FD396D",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft: "12px",
                }}
              >
                {errors.role && touched.role && (
                  <p className="error">{errors.role}</p>
                )}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item>
            <LoadingButton
              type="submit"
              loading={buttonLoading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
            >
              Add Member
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddNewUser;
