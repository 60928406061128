import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  margin: 5px;

  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: lightgrey;
    color: yellow;
    border-radius: 5px;
    cursor: pointer;
  }

  &.active {
    background: #126782;
    color: white;
    border-radius: 5px;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  font-size: 16px;
`;

const SubMenu = ({ nav }) => {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [activeMenu, setActiveMenu] = useState(false);

  return (
    <>
      <SidebarLink
        to={nav.path}
        className={location.pathname === nav.path || activeMenu ? "active" : ""}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "50%",
          }}
        >
          {nav.Icon && (
            <nav.Icon
              sx={{
                ":hover": {
                  color:
                    location.pathname === nav.path || activeMenu
                      ? "#FFFF00"
                      : "#023047",
                },
                color:
                  location.pathname === nav.path || activeMenu
                    ? "#ffffff"
                    : "#023047",

                ml: "25px",
              }}
            />
          )}
          <SidebarLabel
            style={{
              color:
                location.pathname === nav.path || activeMenu
                  ? "#ffffff"
                  : "#023047",

              display: "flex",
              alignItems: "center",
            }}
          >
            {nav.title}
          </SidebarLabel>
        </Typography>
      </SidebarLink>
    </>
  );
};

export default SubMenu;
