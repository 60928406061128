import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { FilterAlt } from "@mui/icons-material";
import { Button, FormControl, Grid, InputLabel, Select } from "@mui/material";
import { CONSTANT } from "../../utils/constant";
import DatePicker from "react-datepicker";
import { sentenceCase } from "../../utils/common.service";
import {
  TbSortAscendingLetters,
  TbSortDescendingLetters,
} from "react-icons/tb";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import "./index.css";

const FilterMenu = (props) => {
  const {
    pageObj,
    setPageObj,
    statusList,
    showReset,
    showNotificationDatePicker,
    showSingleStartDatePicker,
    showSingleEndDatePicker,
    showService,
    showStatus,
    showRangePicker,
    showSort,
    showDelete,
    startDate,
    endDate,
    setDateRange,
    onDateChange,
    setNotificationStartDate,
    notificationStartDate,
    joiningDate,
    setJoiningDate,
    lastDate,
    setLastDate,
    handleResetFilter,
    serviceList,
    postServiceList,
    handleDeleteFeedback,
    checkData,
  } = props;
  const [filterEl, setFilterEl] = React.useState(null);
  const open = Boolean(filterEl);

  const location = useLocation();
  const path = location.pathname.split("/");
  const fileName = path[path.length - 1];

  const handleClose = (order = pageObj.sortingOrder) => {
    setPageObj({ ...pageObj, sortingOrder: order || "" });
    setFilterEl(null);
  };
  const PaperProps = {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "self-end" }}>
        {showReset && (
          <Button
            variant="outlined"
            onClick={handleResetFilter}
            sx={{
              color: "gray",
              borderColor: "gray",
              height: "40px",
            }}
            className="reset-button"
          >
            Reset
          </Button>
        )}

        {showNotificationDatePicker && (
          <Grid sx={{ ml: 3, mt: "3px" }} className="menu">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              isClearable
              placeholderText="DD/MM/YYYY"
              selected={notificationStartDate || null}
              onChange={(update) => {
                setNotificationStartDate(update);
                onDateChange();
              }}
            />
          </Grid>
        )}

        {showSingleStartDatePicker && (
          <Grid className="menu">
            <DatePicker
              className="date-picker"
              isClearable
              placeholderText="DD/MM/YYYY"
              selected={joiningDate}
              onChange={(update) => {
                setJoiningDate(update ? update : null);
              }}
            />
          </Grid>
        )}
        {showSingleEndDatePicker && (
          <Grid className="menu">
            <DatePicker
              className="date-picker"
              isClearable
              placeholderText="DD/MM/YYYY"
              selected={lastDate}
              onChange={(update) => {
                setLastDate(update ? update : null);
              }}
            />
          </Grid>
        )}

        {showService && (
          <FormControl sx={{ minWidth: 140, ml: 3 }} className="menu">
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ color: "#1d2438" }}
            >
              Service type
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={pageObj.service || ""}
              onChange={(e) =>
                setPageObj({
                  ...pageObj,
                  service: e.target.value,
                  pageCount: 1,
                })
              }
              autoWidth
              placeholder="select service"
              label="Service type"
              sx={{ height: 40, mt: "5px" }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>

              {fileName === "posts"
                ? postServiceList?.map((a, i) => (
                    <MenuItem key={i} value={a.key}>
                      {a.value}
                    </MenuItem>
                  ))
                : serviceList?.map((a, i) => (
                    <MenuItem key={i} value={a.key}>
                      {a.value}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        )}

        {showStatus && (
          <FormControl sx={{ minWidth: 140, ml: 3 }} className="menu">
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ color: "#1d2438" }}
            >
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={pageObj.status || ""}
              onChange={(e) =>
                setPageObj({ ...pageObj, status: e.target.value, pageCount: 1 })
              }
              autoWidth
              placeholder="select status"
              label="Status"
              sx={{ height: 40, mt: "5px" }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {statusList?.map((a, i) => (
                <MenuItem key={i} value={a.key}>
                  {a.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showRangePicker && (
          <Grid sx={{ mt: "3px", ml: 3.1 }} className="menu">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              selectsRange={true}
              startDate={startDate || null}
              endDate={endDate || null}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
              placeholderText="DD/MM/YYYY"
            />
          </Grid>
        )}

        {showSort && (
          <Tooltip title="Sort" placement="top-start" className="menu">
            <IconButton
              onClick={(event) => setFilterEl(event.currentTarget)}
              size="small"
              sx={{ ml: 3 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <FilterAlt sx={{ width: 32, height: 32 }} />
            </IconButton>
          </Tooltip>
        )}

        {showDelete && checkData.length !== 0 && (
          <Tooltip className="menu" title="Delete">
            <Grid
              sx={{ ml: 3 }}
              className="export-button"
              onClick={(e) => {
                handleDeleteFeedback();
              }}
            >
              <DeleteIcon sx={{ color: "#707070" }} />
            </Grid>
          </Tooltip>
        )}
      </Box>

      <Menu
        anchorEl={filterEl}
        id="account-menu"
        open={open}
        onClose={() => setFilterEl(null)}
        PaperProps={{ ...PaperProps }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleClose(CONSTANT.ASC)}
          className={`sort-order ${
            pageObj.sortingOrder === CONSTANT.ASC ? CONSTANT.ACTIVE : ""
          }`}
        >
          <Tooltip
            placement="right-start"
            title={sentenceCase(CONSTANT.ASCENDING)}
          >
            <TbSortAscendingLetters className="asc-des" />
          </Tooltip>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose(CONSTANT.DESC)}
          className={`sort-order ${
            pageObj.sortingOrder === CONSTANT.DESC ? CONSTANT.ACTIVE : ""
          }`}
        >
          <Tooltip
            placement="right-start"
            title={sentenceCase(CONSTANT.DESCENDING)}
          >
            <TbSortDescendingLetters className="asc-des" />
          </Tooltip>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default FilterMenu;
