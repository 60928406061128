import { useEffect, useState, useContext } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../../../components/FlexBox";
import SearchInput from "../../../components/SearchInput";
import CustomerTable from "../../../components/userManagement/CustomTable";
import useTitle from "../../../hooks/useTitle";
import "../../../CommonCss/Button.css";
import { BackdropContext } from "../../userManagement/BackdropContext";
import userService from "../../../service/user.service";
import {
  StyledPagination,
  notifyError,
  notifySuccess,
  returnError,
} from "../../../utils/common.service";
import { CONSTANT, PAGE_CONSTANT } from "../../../utils/constant";
import { Small } from "../../../components/Typography";
import { Stack } from "@mui/system";
import "../../userManagement/UserList.css";
import moment from "moment";
import FilterMenu from "../../../components/card/filterMenu";
import UserDetailsForm from "../../../components/userManagement/UserDetailsForm";
import CustomizationUserFormDialog from "../../../components/Layouts/Modal/CustomizationUserFormModal";
import QuizDetailsForm from "../../QuizManagment/QuizDetailsForm";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const UsersHistory = () => {
  useTitle("History");

  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 5,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
  });
  const [usersData, setUsersData] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [search, setSearch] = useState("");

  useEffect(() => {
    document.title = PAGE_CONSTANT.CUSTOMERS;
  }, []);
  const [windowHeight, setWindowHeight] = useState(800);

  useEffect(() => {
    const height = window.innerHeight;
    setWindowHeight(height);
  }, []);

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      fetchData({ ...pageObj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, pageObj]);

  const UserListColumnShape = [
    {
      Header: "User Id",
      accessor: "userId",
      minWidth: 60,
      maxWidth: 110,
      Cell: ({ row }) => {
        const { userId, guestUser } = row.original;
        return userId !== "" ? (
          <Small
            sx={{
              color: "text.primary",
            }}
            className="post-text-ellipsis post-text-ellipsis-dot transform-text-ellipsis transform-text-ellipsis-dot"
            onClick={(e) => {
              handleOpenUserDetailsModal(userId, "user");
            }}
          >
            <Tooltip title={userId} placement="top-start">
              {userId || "-"}
            </Tooltip>
          </Small>
        ) : (
          <Small sx={{ color: "text.primary" }}>
            {guestUser ? (
              <>
                <Tooltip title="Guest User" placement="top-start">
                  {guestUser}
                  <span
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    G
                  </span>
                </Tooltip>
              </>
            ) : (
              "-"
            )}
          </Small>
        );
      },
    },
    {
      Header: "Quiz Id",
      accessor: "quizId",
      minWidth: 60,
      maxWidth: 110,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
          className="post-text-ellipsis post-text-ellipsis-dot transform-text-ellipsis transform-text-ellipsis-dot"
          onClick={(e) => {
            handleOpenUserDetailsModal(value, "quiz");
          }}
        >
          <Tooltip title={value} placement="top-start">
            {value || "-"}
          </Tooltip>
        </Small>
      ),
    },
    {
      Header: "History Id",
      accessor: "_id",
      minWidth: 60,
      maxWidth: 125,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
          className="post-text-ellipsis post-text-ellipsis-dot transform-text-ellipsis transform-text-ellipsis-dot"
        >
          <Tooltip title={value} placement="top-start">
            {value || "-"}
          </Tooltip>
        </Small>
      ),
    },

    {
      Header: "Score",
      accessor: "score",
      maxWidth: 80,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Ads Watch",
      accessor: "adWatch",
      minWidth: 70,
      maxWidth: 120,

      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Lifeline",
      accessor: "usedLifeLines",
      maxWidth: 75,
      Cell: ({ value }) => {
        return (
          <Small
            sx={{
              color: "text.primary",
              cursor: "pointer",
            }}
          >
            {value?.join(", ") || "-"}
          </Small>
        );
      },
    },
    {
      Header: "Time Usage [sec.]",
      accessor: "timeUsage",
      minWidth: 180,
      Cell: ({ row }) => {
        const { totalTimeUsed, totalTime } = row.original;
        return (
          <Small
            sx={{
              color: "text.primary",
              cursor: "pointer",
            }}
          >
            {`${totalTimeUsed} Out of  ${totalTime}` || "-"}
          </Small>
        );
      },
    },
    {
      Header: "Questions",
      accessor: "totalQuestion",
      maxWidth: 100,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Correct Answer",
      accessor: "correctAnswer",
      minWidth: 80,
      maxWidth: 100,
      Cell: ({ value }) => {
        return (
          <Small
            sx={{
              cursor: "pointer",
              minWidth: "100px",
              backgroundColor: "green",
              padding: "10px",
              borderRadius: "5px",
              color: "white",
            }}
          >
            {" "}
            {value || 0}
          </Small>
        );
      },
    },
    {
      Header: "Wrong Answer",
      accessor: "wrongAnswer",
      minWidth: 80,
      maxWidth: 100,
      Cell: ({ value }) => {
        return (
          <Small
            sx={{
              cursor: "pointer",
              minWidth: "100px",
              backgroundColor: "red",
              padding: "10px",
              borderRadius: "5px",
              color: "white",
            }}
          >
            {" "}
            {value || 0}
          </Small>
        );
      },
    },
  ];

  const fetchData = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = {
        ...obj,
        pageCount: obj.pageCount - 1,
        filter: {
          fromDate:
            startDate && moment(startDate).isValid()
              ? moment(startDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
          toDate:
            endDate && moment(endDate).isValid()
              ? moment(endDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
        },
      };

      userService
        .fetchResultData({ ...data })
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setUsersData(res?.data || []);
            setTotalRecord(res?.totalRecords || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalRecords || 0);
            setUsersData([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(returnError(err));
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(returnError(err));
    }
  };

  const handleOpenUserDetailsModal = (id, key) => {
    if (key === "user") {
      try {
        setBackdropOpen(true);
        userService
          .getUserById(id)
          .then((res) => {
            const { data, message } = res;
            if (res.status === 200) {
              setBackdropOpen(false);
              setDisplayData(data);
              setOpenUserDetailsModal(true);
            } else {
              setBackdropOpen(false);
              notifyError(message);
            }
          })
          .catch((err) => {
            notifyError(err ? err?.response?.data?.message : err?.message);
          });
      } catch (err) {
        setBackdropOpen(false);
        notifyError(err ? err?.response?.data?.message : err?.message);
      }
    } else if (key === "quiz") {
      try {
        setBackdropOpen(true);
        userService
          .getQuizById(id)
          .then((res) => {
            const { data, message } = res;
            if (res.status === 200) {
              setBackdropOpen(false);
              setDisplayData(data);
              setOpenUserDetailsModal(true);
            } else {
              setBackdropOpen(false);
              notifyError(message);
            }
          })
          .catch((err) => {
            notifyError(err ? err?.response?.data?.message : err?.message);
          });
      } catch (err) {
        setBackdropOpen(false);
        notifyError(err ? err?.response?.data?.message : err?.message);
      }
    }
  };

  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
    setDisplayData();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleResetFilter = () => {
    setDateRange([null, null]);
    setPageObj({
      ...pageObj,
      search: "",
      sortingField: "",
      sortingOrder: "desc",
      role: "customer",
    });
    setSearch("");
  };

  return (
    <Box pt={2}>
      <StyledFlexBox sx={{ mt: 2 }} className="style-box">
        <SearchInput
          placeholder="Search user..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          setPageObj={setPageObj}
          pageObj={pageObj}
          showReset={true}
          showSort={false}
          showStatus={false}
          showRangePicker={true}
          showExport={false}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleResetFilter={handleResetFilter}
        />
      </StyledFlexBox>

      <Grid>
        <CustomerTable
          isSort={true}
          pageObj={pageObj}
          columnShape={UserListColumnShape}
          data={usersData}
          hidePagination={false}
          createSortHandler={createSortHandler}
          setHeight={"calc(67vh - 10px)"}
          setHighHeight={"calc(75vh - 10px)"}
          windowHeight={windowHeight}
        />
      </Grid>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CustomizationUserFormDialog
        title="User Detail"
        open={openUserDetailsModal}
        handleClose={handleCloseUserDetailsModal}
        children={
          displayData?.title ? (
            <QuizDetailsForm
              closeModal={handleCloseUserDetailsModal}
              data={displayData}
            />
          ) : (
            <UserDetailsForm
              closeModal={handleCloseUserDetailsModal}
              data={displayData}
            />
          )
        }
      />

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Users:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#126782",
            }}
          >
            {totalRecord || 0}
          </Small>
        </Grid>

        {usersData?.length > 0 && (
          <Stack>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj?.pageCount || 0}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        )}
      </Grid>
    </Box>
  );
};

export default UsersHistory;
