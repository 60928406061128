import React from "react";
import { Grid, IconButton, Divider, Typography } from "@mui/material";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import { capitalizeFirstLetter } from "../../utils/common.service";
import "../category/CategoryDetailsForm.css";
import { Box } from "@mui/system";
import { BASE_URL, CONSTANT } from "../../utils/constant";
import moment from "moment";
import { Small } from "../../components/Typography";
import RankTable from "./RankTable";
import QuestionTable from "./QuestionTable";

const QuizDetailsForm = (props) => {
  const { closeModal, data } = props;

  const UserListColumnShape = [
    {
      Header: "Rank",
      accessor: "rank",
      minWidth: 200,
      Cell: ({ row }) => {
        const { from, to } = row.original;
        return (
          <Small
            sx={{
              color: "text.primary",
              cursor: "pointer",
            }}
          >
            {`Range: ${from} - ${to}` || `Range: 0`}
          </Small>
        );
      },
    },
    {
      Header: "Price",
      accessor: "price",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
  ];

  const UserListColumnShapeQuestion = [
    {
      Header: "Question",
      accessor: "question",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Options",
      accessor: "options",
      minWidth: 200,
      Cell: ({ row, value }) => {
        const { options } = row.original;

        return (
          <>
            <ol type="A">
              {options.map((data) => (
                <li style={{ color: "black" }}>
                  <Small
                    key={data?.value}
                    sx={{
                      color: "text.primary",
                    }}
                  >
                    {data?.value || "-"}
                  </Small>
                  <br />
                </li>
              ))}
            </ol>
          </>
        );
      },
    },
    {
      Header: "Answer",
      accessor: "rightOption",
      minWidth: 200,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                color: "text.primary",
              }}
            >
              {value?.map((data) => `${data.value}`).join(" , ")}
            </Small>
          </>
        );
      },
    },
  ];

  return (
    <>
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
          minWidth: "75%",
        }}
      >
        <h3>Quiz Details</h3>

        <IconButton aria-label="close">
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          sx={{
            overflow: "auto",
          }}
        >
          <Grid sx={{ display: "flex", gap: "10%", mx: "30px" }}>
            <Grid item xs={4}>
              {data?.attachment?.filename && (
                <img
                  src={`${BASE_URL}document/quiz_media/${data?.attachment?.filename}`}
                  alt="QuizPoster"
                  className="profileImg"
                />
              )}
            </Grid>

            <Grid container>
              <Grid item xs={4}>
                <Typography sx={{ color: "#4f4f4f" }}>Name</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {capitalizeFirstLetter(data?.title) || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "#4f4f4f" }}>Category</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?.categoryTitle || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "#4f4f4f" }}>Quiz Id</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?._id || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Updated Date</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {moment(data?.updatedAt).format(CONSTANT.DATE_FORMATE)}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Created Date</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {moment(data?.createdAt).format(CONSTANT.DATE_FORMATE)}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Type</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?.type || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: "20px", mb: "20px" }} />
          <Grid sx={{ display: "flex", gap: "10%", mx: "30px", mb: "20px" }}>
            <Grid container>
              <Grid item xs={4} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Entry Coin</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?.entryCoin || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>User Playing</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {data?.userPlaying || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Time Limit</Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {`${data?.totalTimeSecond} sec` || "-"}
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>Description</Typography>
                <Typography
                  sx={{ fontWeight: 600, pr: 5 }}
                  className="letter-spacing"
                >
                  {`${data?.description} sec` || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#4f4f4f" }}>
                  Total Questions
                </Typography>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  {`${data?.totalQuestion} sec` || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: "20px", mb: "20px" }} />
          <Grid sx={{ mx: "30px", mb: "20px" }}>
            {data?.prizeRankList && (
              <Grid item xs={12} sx={{ mt: "20px" }}>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  Price Rank List
                </Typography>
                <RankTable
                  isSort={false}
                  columnShape={UserListColumnShape}
                  data={data?.prizeRankList}
                  hidePagination={false}
                />
              </Grid>
            )}
          </Grid>
          <Divider sx={{ mt: "20px", mb: "20px" }} />
          <Grid sx={{ mx: "30px", mb: "20px" }}>
            {data?.questions && (
              <Grid item xs={12} sx={{ mt: "20px" }}>
                <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
                  Questions List
                </Typography>
                <QuestionTable
                  isSort={false}
                  columnShape={UserListColumnShapeQuestion}
                  data={data?.questions}
                  hidePagination={false}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default QuizDetailsForm;
