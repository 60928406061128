import { LoadingButton } from "@mui/lab";
import { Box, Card } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import LightTextField from "../../components/LightTextField";
import { H1, Small } from "../../components/Typography";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MESSAGE } from "../../utils/constant.jsx";
import logo from "../../Image/logo/Frame1.png";
import "../../CommonCss/Button.css";
import userService from "../../service/user.service";
import { notifyError, notifySuccess } from "../../utils/common.service";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    submit: null,
  };
  // form field value validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(MESSAGE.EMAIL_VALID)
      .max(255)
      .required(MESSAGE.EMAIL_REQUIRED),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          userService
            .forgotPassword({ email: values.email })
            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setLoading(false);
                notifySuccess(message);
                localStorage.setItem(btoa("email"), btoa(values.email));
                navigate("/reset-password");
              } else {
                setLoading(false);
                notifyError(res?.message || MESSAGE.NETWORK_ERROR);
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  return (
    <FlexBox
      height="100vh"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Card sx={{ padding: 4, maxWidth: 600, marginTop: 4, boxShadow: 1 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={1}
        >
          <Box width={130} mb={1}>
            <img src={logo} width="100%" alt="Uko Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Forget your password
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my={2}>
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <LightTextField
              fullWidth
              name="email"
              type="email"
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email || ""}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <Box sx={{ mt: 4 }}>
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth
                className="button"
              >
                Reset
              </LoadingButton>
            </Box>
          </form>

          <Small margin="auto" mt={3} color="text.disabled">
            Don't have an account?{" "}
            <Link to="/register">
              <Small color="primary.main">Create an account</Small>
            </Link>
          </Small>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default ForgetPassword;
