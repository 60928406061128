import { useEffect, useState, useContext } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import SearchInput from "../../components/SearchInput";
import StaffTable from "../../components/userManagement/CustomTable";
import useTitle from "../../hooks/useTitle";
import "../../CommonCss/Button.css";
import { BackdropContext } from "../../pages/userManagement/BackdropContext";
import userService from "../../service/user.service";
import {
  StyledPagination,
  notifyError,
  notifySuccess,
  returnError,
  showConfirmDialog,
} from "../../utils/common.service";
import CustomizationDialog from "../../components/Layouts/Modal/CustomizationModal";
import CategoryForm from "./CategoryForm";
import {
  BASE_URL,
  CONSTANT,
  MESSAGE,
  PAGE_CONSTANT,
} from "../../utils/constant";
import CustomizationUserFormDialog from "../../components/Layouts/Modal/CustomizationUserFormModal";
import { Small } from "../../components/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Stack } from "@mui/system";
import "./CategoryList.css";
import NodataFound from "../../components/Layouts/NodataFound";
import FilterMenu from "../../components/card/filterMenu";
import moment from "moment";
import AddNewCategory from "./AddNewCategory";
import CategoryDetailsForm from "./CategoryDetailsForm";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const UserList = () => {
  useTitle("Categories");
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);

  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 3,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
    role: "",
  });
  const [search, setSearch] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [windowHeight, setWindowHeight] = useState(800);

  useEffect(() => {
    const height = window.innerHeight;
    setWindowHeight(height);
  }, []);

  useEffect(() => {
    document.title = PAGE_CONSTANT.CATEGORY;
  }, []);

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      fetchData({ ...pageObj });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, pageObj]);

  const UserListColumnShape = [
    {
      Header: "Category Image",
      accessor: "attachment",
      minWidth: 200,
      Cell: ({ value }) => {
        return (
          <FlexBox alignItems="center">
            {value?.filename ? (
              <img
                src={
                  `${BASE_URL}document/category_media/${value?.filename}` ||
                  null
                }
                alt="CategoryPoster"
                className="Category-image"
              />
            ) : (
              "-"
            )}
          </FlexBox>
        );
      },
    },

    {
      Header: "Title",
      accessor: "title",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Category Id",
      accessor: "_id",
      minWidth: 170,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Description",
      accessor: "description",
      minWidth: 170,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Updated Date",
      accessor: "updatedAt",
      minWidth: 100,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {value ? moment(value).format(CONSTANT.DATE_FORMATE) : "-"}
            </Small>
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {value ? moment(value, "HH:mm").format("hh:mm A") : ""}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row }) => {
        const { _id } = row.original;
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "right" }}>
            <Tooltip
              title="Category Detail"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
                mr: "10px",
              }}
              onClick={(e) => {
                handleOpenCategoryDetailsModal(_id);
              }}
            >
              <RemoveRedEyeIcon className="icons-details" color="black" />
            </Tooltip>

            <Tooltip
              title="Edit"
              placement="top-start"
              onClick={(e) => {
                handleOpenModal(_id);
              }}
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
              }}
            >
              <DriveFileRenameOutlineRoundedIcon
                className="icons-edit"
                color="primary"
              />
            </Tooltip>

            <Tooltip
              title="Delete"
              placement="top-start"
              sx={{
                cursor: "pointer",
                marginLeft: "10px",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
              }}
              onClick={(e) => {
                handleDeleteCategory(_id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                className="icons-delete"
                color="error"
              />
            </Tooltip>
          </FlexBox>
        );
      },
    },
  ];
  const fetchData = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = {
        ...obj,
        pageCount: obj.pageCount - 1,
        filter: {
          fromDate:
            startDate && moment(startDate).isValid()
              ? moment(startDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
          toDate:
            endDate && moment(endDate).isValid()
              ? moment(endDate).format(CONSTANT.DATE_PICKER_FORMATE)
              : null,
        },
      };

      userService
        .fetchCategory({ ...data })
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setCategoryData(res?.data || []);
            setTotalRecord(res?.totalRecords || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalRecords || 0);
            setCategoryData([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(returnError(err));
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(returnError(err));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const handleCloseModal = () => {
    setCategoryId("");
    setOpenModal(false);
  };

  const handleOpenModal = async (_id) => {
    if (_id) {
      setCategoryId(_id);
      fetchCategoryById(_id);
    } else {
      setOpenModal(true);
    }
  };

  const fetchCategoryById = (id) => {
    try {
      setBackdropOpen(true);
      userService
        .getCategoryById(id)
        .then((res) => {
          const { data, message } = res;
          if (res.status === 200) {
            setBackdropOpen(false);
            setResponseData(data);
            setOpenModal(true);
          } else {
            setBackdropOpen(false);
            notifyError(message);
          }
        })
        .catch((err) => {
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleOpenCategoryDetailsModal = (id) => {
    try {
      setBackdropOpen(true);
      userService
        .getCategoryById(id)
        .then((res) => {
          const { data, message } = res;
          if (res.status === 200) {
            setBackdropOpen(false);
            setDisplayData(data);
            setOpenUserDetailsModal(true);
          } else {
            setBackdropOpen(false);
            notifyError(message);
          }
        })
        .catch((err) => {
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
  };

  const handleDeleteCategory = async (_id) => {
    showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed) => {
      if (confirmed) {
        const responseData = await userService.deleteCategory(_id);
        if (responseData && responseData.status === 200) {
          notifySuccess(responseData.message);
          if (categoryData.length === 1 && pageObj.pageCount > 1) {
            setPageObj((prevPageObj) => ({
              ...prevPageObj,
              pageCount: prevPageObj.pageCount - 1,
            }));
          } else {
            setPageObj({
              ...pageObj,
              refresh: Date.now(),
            });
          }
        } else {
          notifyError(responseData.message || MESSAGE.NETWORK_ERROR);
        }
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleResetFilter = () => {
    setDateRange([null, null]);
    setPageObj({
      ...pageObj,
      search: "",
      sortingField: "",
      sortingOrder: "desc",
      role: "",
    });
    setSearch("");
  };

  return (
    <Box pt={2}>
      <Grid
        sx={{ display: "flex", justifyContent: "end" }}
        className="style-box"
      >
        <Button onClick={() => handleOpenModal()} className="button">
          Add New Category
        </Button>
      </Grid>
      <StyledFlexBox sx={{ mt: 2 }} className="style-box-below">
        <SearchInput
          sx={{ width: "inherit" }}
          placeholder="Search staffs..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          setPageObj={setPageObj}
          pageObj={pageObj}
          showReset={true}
          showSort={false}
          showStatus={false}
          showRangePicker={true}
          showExport={true}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleResetFilter={handleResetFilter}
        />
      </StyledFlexBox>

      <Grid>
        <StaffTable
          isSort={true}
          pageObj={pageObj}
          columnShape={UserListColumnShape}
          data={categoryData}
          hidePagination={false}
          createSortHandler={createSortHandler}
          setHeight={"calc(63vh - 10px)"}
          setHighHeight={"calc(71vh - 10px)"}
          windowHeight={windowHeight}
        />
        {!backdropOpen &&
          (!categoryData || (categoryData.length <= 0 && <NodataFound />))}
      </Grid>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <CustomizationDialog
        open={openModal}
        handleClose={handleCloseModal}
        children={
          !categoryId ? (
            <AddNewCategory
              closeForm={handleCloseModal}
              pageObj={pageObj}
              setPageObj={setPageObj}
            />
          ) : (
            <CategoryForm
              id={categoryId}
              closeModal={handleCloseModal}
              responseData={responseData}
              pageObj={pageObj}
              setPageObj={setPageObj}
            />
          )
        }
      />

      <CustomizationUserFormDialog
        title="Staff Information"
        open={openUserDetailsModal}
        handleClose={handleCloseUserDetailsModal}
        children={
          <CategoryDetailsForm
            closeModal={handleCloseUserDetailsModal}
            data={displayData}
          />
        }
      />

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Category:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#126782",
            }}
          >
            {totalRecord || 0}
          </Small>
        </Grid>

        {categoryData?.length > 0 && (
          <Stack>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj?.pageCount || 0}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        )}
      </Grid>
    </Box>
  );
};

export default UserList;
