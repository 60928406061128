import React from "react";
import { Fragment, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { userTokenMethod } from "../../utils/common.service";

const AuthGuard = ({ children }) => {
  const token = userTokenMethod("get");
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={"/login"} replace={true} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
