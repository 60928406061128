// export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = "http://localhost:7000/";
export const BASE_URL = "https://api.atozquiz.com/";

export const API_CONSTANT = {
  /*------ Login ------*/
  SIGN_UP_DATA: "user/signUp",
  SUPER_ADMIN_SIGN_Up: "user/superAdminSignUp",
  LOGIN: "user/loginUser",
  FORGET_PASSWORD: "user/forgotPassword",
  RESET_PASSWORD: "user/resetForgotPassword",
  VERIFICATION: "user/verification",
  PROFILE_RESET_PASSWORD: "user/resetPassword",
  UPDATE_USER_PROFILE: "user/updateUserProfile",

  /*------ User ------*/
  FETCH_USERS: "user/fetchUsers",
  ADD_USER: "user/createUser",
  F: "user/fetchUserById/",
  FETCH_COIN_HISTORY_BY_ID: "coins/getAllCoinHistoryByUser/",
  DELETE_COIN_HISTORY: "coins/deleteCoinHistoryByID/",
  DELETE_USER_BY_ID: "user/deleteUserById/",
  UPDATE_STATUS: "user/updateStatusById/",
  UPDATE_USER: "user/updateUserById/",
  USER_COUNTS: "user/fetchUsersCounts",

  /*------ Feedback ------*/
  FEEDBACK: "inquiry/fetchInquiry",
  FETCH_FEEDBACK_BY_ID: "/feedback/getFeedbackAndReviewById/",
  FEEDBAck_UPDATE: "/feedback/updateFeedbackAndReviewById/",
  DELETE_INQUIRY: "inquiry/deleteInquiryAndReviewById",

  /*------ Category ---------*/
  FETCH_CATEGORY: "category/getAllCategory",
  DELETE_CATEGORY: "category/deleteCategory/",
  EDIT_CATEGORY: "category/updateCategoryById/",
  ADD_CATEGORY: "category/addCategory",
  FETCH_CATEGORY_BY_ID: "category/getCategoryById/",

  /*------ Quiz ---------*/
  FETCH_QUIZ: "quiz/getAllQuiz",
  DELETE_QUIZ: "quiz/deleteQuiz/",
  EDIT_QUIZ: "quiz/updateQuizById/",
  ADD_QUIZ: "quiz/createQuiz",
  FETCH_QUIZ_BY_ID: "quiz/fetchQuizById/",

  /*------------ QuizResultData ------------------*/
  FETCH_RESULT_DATA: "quiz/fetchAllQuizResult",

  /*------------ Blog ------------------*/
  CREATE_BLOG: "blogs/addBlogs",
  GET_ALL_BLOGS: "blogs/getAllBlogs",
  GET_BLOG_BY_ID: "blogs/getBlogsById/",
  UPDATE_BLOG_BY_ID: "blogs/updateBlogsById/",
  DELETE_BLOG_BY_ID: "blogs/deleteBlogs/",
};

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const MESSAGE = {
  NAME: "Name is Required!",
  EMAIL_VALID: "Must be a valid email",
  EMAIL_REQUIRED: "Email is required",
  PASSWORD_MINIMUM: "Password should be of minimum 6 characters length",
  PASSWORD_REQUIRED: "Password is required",
  NEW_PASSWORD_REQUIRED: "New Password is required",
  CURRENT_PASSWORD_REQUIRED: "Current Password is required",
  CONFIRM_PASSWORD_MINIMUM:
    "ConfirmPassword should be of minimum 6 characters length",
  CONFIRM_PASSWORD_REQUIRED: "Confirm Password is required",
  PASSWORD_MATCH: "Password is not match",
  PHONE_NO_MINIMUM: "Phone No should be of minimum 12 characters length",
  PHONE_NO_MAXIMUM: "Phone No should be of maximum 12 characters length",
  OTP: "Otp code is required",
  OTP_MIN: "Otp must be a 4 digit",
  ROLE: "Role is Required!",
  NETWORK_ERROR: "Network Error occurred",
  TITLE: "Title is required",
  QUESTION: "Question is Required!",
  ANSWER: "Answer is Required!",
  NO_FILE_SELECT: "No file selected",
};

export const PAGE_CONSTANT = {
  DASHBOARD: "Dashboard",
  CATEGORY: "Category",
  QUIZ_LIST: "Quizzes",
  CUSTOMERS: "Users",
  BLOG: "Blog",
  FEED_BACK_LIST: "Feedback",
  PROFILE: "Profile",
};

export const CONSTANT = {
  NULL_STRING: "",
  DASH_STRING: "-",
  UNDEFINED: "undefined",
  DATE_FORMATE: "Do MMM, YYYY",
  SOMETHING_WRONG: "Something went wrong",
  SUCCESS: "success",
  WARNING: "warning",
  DESC: "desc",
  ASC: "asc",
  ERROR: "error",
  ACTIVE: "active",
  ASCENDING: "ascending",
  DESCENDING: "descending",
  DATE_PICKER_FORMATE: "YYYY-MM-DD",
  DELETE_CONFIRMATION: "Are you sure to delete record?",
  DELETE_QUESTIOPN: "Are you sure to delete this question?",

  RATE_LIST: [
    { key: 1, value: "One" },
    { key: 2, value: "Two" },
    { key: 3, value: "Three" },
    { key: 4, value: "Four" },
    { key: 5, value: "Five" },
  ],

  ROLE_LIST: [
    { id: "super_admin", label: "Super Admin" },
    { id: "admin", label: "Admin" },
    { id: "customer", label: "Customer" },
  ],

  STATUS_LIST: [
    { id: "True", label: "True" },
    { id: "False", label: "False" },
    { id: "suspend", label: "Suspend" },
  ],

  OPTION_LIST: [
    { key: "101", value: "A." },
    { key: "102", value: "B." },
    { key: "103", value: "C." },
    { key: "104", value: "D." },
    { key: "105", value: "E." },
    { key: "106", value: "F." },
    { key: "107", value: "G." },
    { key: "108", value: "H." },
    { key: "109", value: "I." },
    { key: "110", value: "J." },
    { key: "111", value: "K." },
  ],
};

export const BLOG_EDITOR_FORMATE = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "align",
];

export const BLOG_EDITOR_MODULES = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ font: [] }],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    [{ align: [] }],
  ],
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
};
