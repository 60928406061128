import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { createContext } from "react";
import { THEMES } from "../utils/constant";

const initialSettings = {
  direction: "ltr",
  theme: THEMES.LIGHT,
  responsiveFontSizes: true,
};
export const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: (arg) => {},
});

const SettingsProvider = ({ children }) => {
  const { data: settings, storeData: setSettings } = useLocalStorage(
    "settings",
    initialSettings
  );

  const saveSettings = (updateSettings) => {
    setSettings(updateSettings);
  };

  return (
    <SettingsContext.Provider value={{ settings, saveSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
