import { useState } from "react";
import { Card, Grid, Typography } from "@mui/material";
import { TextFieldWrapper } from "../components/authentication/StyledComponents";
import LightTextField from "../components/LightTextField";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useFormik } from "formik";
import userService from "../service/user.service";
import { notifyError, notifySuccess } from "../utils/common.service";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { MESSAGE } from "../utils/constant";

const ProfileResetPassword = (props) => {
  const { userId } = props;
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [buttonLoading, setButtonLoading] = useState(false);

  const initialValues = {
    email: userId?.email,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(MESSAGE.CURRENT_PASSWORD_REQUIRED),
    newPassword: Yup.string()
      .min(6, MESSAGE.PASSWORD_MINIMUM)
      .required(MESSAGE.NEW_PASSWORD_REQUIRED),
    confirmPassword: Yup.string()
      .min(6, MESSAGE.CONFIRM_PASSWORD_MINIMUM)
      .required(MESSAGE.CONFIRM_PASSWORD_REQUIRED),
  });

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);
          userService
            .profileResetPassword({
              email: values?.email,
              oldPassword: values?.oldPassword,
              newPassword: values?.newPassword,
              confirmPassword: values?.confirmPassword,
            })

            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setButtonLoading(false);
                notifySuccess(message);
                logout();
                navigate("/login");
              } else {
                setButtonLoading(false);
                notifyError(message);
              }
            })
            .catch((err) => {
              setButtonLoading(false);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });
  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            boxSizing: "border-box",
            borderRadius: "10px",
            boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
            overflow: "hidden",
            width: "70%",
            height: "100%",
            p: 3,
          }}
        >
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              <Grid spacing={1.8} container>
                <Grid item xs={12}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      pb: "5px",
                    }}
                  >
                    Email
                  </Typography>
                  <TextFieldWrapper sx={{ width: "100%" }}>
                    <LightTextField
                      fullWidth
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled
                      sx={{
                        backgroundColor: "#e5eaf2",
                        color: "black",
                        borderRadius: "9px",
                      }}
                    />
                  </TextFieldWrapper>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      pb: "5px",
                    }}
                  >
                    Current Password
                  </Typography>
                  <TextFieldWrapper sx={{ width: "100%" }}>
                    <LightTextField
                      fullWidth
                      name="oldPassword"
                      type="password"
                      label="Current Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.oldPassword || ""}
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                      helperText={touched.oldPassword && errors.oldPassword}
                    />
                  </TextFieldWrapper>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      pb: "5px",
                    }}
                  >
                    New Password
                  </Typography>
                  <TextFieldWrapper sx={{ width: "100%" }}>
                    <LightTextField
                      fullWidth
                      name="newPassword"
                      type="password"
                      label="New Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.newPassword || ""}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                    />
                  </TextFieldWrapper>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    as="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      pb: "5px",
                    }}
                  >
                    Confirm Password
                  </Typography>
                  <TextFieldWrapper sx={{ width: "100%" }}>
                    <LightTextField
                      fullWidth
                      name="confirmPassword"
                      type="password"
                      label="Confirm Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword || ""}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                    />
                  </TextFieldWrapper>
                </Grid>

                <Grid sx={{ mt: 2 }} item xs={12}>
                  <Box>
                    <LoadingButton
                      sx={{ maxWidth: "200px" }}
                      type="submit"
                      loading={buttonLoading}
                      loadingPosition="start"
                      variant="contained"
                      fullWidth
                      className="button"
                    >
                      Reset Password
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Card>
      </Grid>
    </>
  );
};

export default ProfileResetPassword;
